@import "variables";
@import "mixins";
.c-app-login {
    position: relative;
    padding: 85px 0;
}
.header-login-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 0 20px 15px;
}
.change-language-login {
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 3;
    .btn-en,
    .btn-fr {
        padding: 0;
        color: $clr-89;
        font-weight: 900;
        border: none;
        &.is-actived,
        &:hover {
            color: #6f7682;
            font-weight: 600;
        }
        &:focus,
        &:visited {
            outline: none;
            border: none;
        }
    }
    .slash {
        color: #afb3bf;
        margin-left: 5px;
        color: #afb3bf;
        font-weight: 500;
        margin-right: 3px;
    }
    .btn-fr {
        margin-left: 28px;
        position: relative;
    }
    .btn-en {
        margin-left: 3px;
    }
}

.card-login {
    border: none;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    display: block;
}

.login-form {
    // box-shadow: 0 2px 62px rgba(#000, 0.07);
    border: none;
    border-radius: 8px;
    position: relative;
    max-width: 352px;
    margin: auto;
    background-color: #fff;
    padding: 40px 25px;
    transition: all ease 0.5s;
    @include breakpoint(sm) {
        padding: 55px 45px 80px;
    }
    // &:before {
    //     content: "";
    //     display: block;
    //     width: 100%;
    //     height: 8px;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     background: rgba(243, 112, 56, 1);
    //     background: -moz-linear-gradient(left, rgba(243, 112, 56, 1) 0%, rgba(253, 194, 132, 1) 100%);
    //     background: -webkit-gradient(left top, right top, color-stop(0%, rgba(243, 112, 56, 1)), color-stop(100%, rgba(253, 194, 132, 1)));
    //     background: -webkit-linear-gradient(left, rgba(243, 112, 56, 1) 0%, rgba(253, 194, 132, 1) 100%);
    //     background: -o-linear-gradient(left, rgba(243, 112, 56, 1) 0%, rgba(253, 194, 132, 1) 100%);
    //     background: -ms-linear-gradient(left, rgba(243, 112, 56, 1) 0%, rgba(253, 194, 132, 1) 100%);
    //     background: linear-gradient(to right, rgba(243, 112, 56, 1) 0%, rgba(253, 194, 132, 1) 100%);
    //     // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f37038', endColorstr='#fdc284', GradientType=1 );
    //     border-radius: 8px 8px 0 0;
    // }
    .box-left {
        border-right: 2px solid #F41828;
        text-align: right;
        padding: 10px 15px;
        img {
            margin-right: 0;
            width: 100%;
            max-width: 180px;
            height: auto
        }
    }
    .box-right {
        display: flex;
        align-items: center;
        img {
            margin-right: 0;
            width: 100%;
            max-width: 180px;
            height: auto
        }
    }
    .login-form-logo {
        margin-bottom: 25px;
        img {
            margin-bottom: 17px;
        }
        h3 {
            margin: 0;
            color: #252525;
            font-family: $LatoHeavy;
            font-size: 28px;
        }
        .note-login {
            font-size: 12px;
            margin-top: 14px;
            padding-top: 0;
        }
    }
    
    .login-form-input {
        padding: 0;
        .form-group {
            margin-bottom: 15px;
        }
        .form-group-user {
            .input-group-text {
                background-image: url('/images/icons/login/Icons-login-user.svg');
                background-position: center center;
                background-repeat:  no-repeat;
                background-size: 10px auto;
            }
        }
        .form-group-password {
            .input-group-text {
                background-image: url('/images/icons/login/Icons-Login-password-02.svg');
                background-position: center center;
                background-repeat:  no-repeat;
                background-size: 10px auto;
            }
        }
        .form-group-password-open {
            .input-group-text {
                background-image: url('/images/icons/login/Icons-Login-Create-password.svg');
                background-position: center center;
                background-repeat:  no-repeat;
                background-size: 10px auto;
            }
        }
        .input-group {
            margin-bottom: 0 !important;
            .form-control {
                // border-color: $clr-89;
                // @include placeholder($clr-89);
                font-size: 12px;
                // font-weight: 700;
                height: 34px;
                border-left: none;
                padding-left: 0;
                // color: $clr-89 !important;
                background-color: #f2f2f2;
                border-color: #f2f2f2;
                &:focus {
                    outline: none;
                }
            }
        }
        button {
            &.btn-submit {
                background-color: #FF6F1B;
                color: #ffffff;
            }
            &.btn-link {
                color: #FF6F1B;
            }
        }
        .input-group-text {
            background-color: #f2f2f2 !important;
            border-right: none;
            color: transparent;
            border-color: #f2f2f2;
            padding: 6px 2px;
        }
        .form-control.is-invalid {
            background-image: none;
        }
        .invalid-feedback {
            display: none;
        }
        // .custom-checkbox {
        //     padding-left: 20px;
        //     .custom-control-label {
        //         &:before {
        //             left: -20px;
        //             width: 14px;
        //             height: 14px;
        //             border-radius: 2px;
        //             border-color: $clr-89;
        //         }
        //         &:after {
        //             left: -17px;
        //             width: 8px;
        //             height: 8px;
        //             // top: 4px;
        //             margin-top: 3px;
        //             border-radius: 2px;
        //         }
        //     }
        // }
    }
    .login-option {
        margin-bottom: 15px;
        .form-group {
            display: block;
            margin: 0;
            .col-md-12 {
                padding: 0;
            }
        }
        .custom-control-label {
            color: $clr-89;
            font-weight: 400;
            font-size: 11px;
            padding-top: 3px;
        }
        .btn-link {
            color: $clr-main;
            font-weight: normal;
            font-size: 11px;
            padding: 0;
            border: none;
            &:focus {
                outline: none;
                border: none;
            }
        }
        // .custom-control-input~.custom-control-label::before {
        //     width: 15px;
        //     height: 15px;
        //     border-color: #c8c8c8;
        // }
        // .custom-control-input:checked~.custom-control-label::before {
        //     background-color: #fc6a29;
        //     border-color: #fc6a29;
        // }
        // .custom-control-input:checked~.custom-control-label::after {
        //     // background-color: #ef662f;
        //     background-image: url(/images/checked-2.png);
        //     width: 9px;
        //     height: 8px;
        //     top: 4px;
        //     left: -17px;
        //     background-size: 100% 100%;
        // }
        // .custom-control-input:focus ~ .custom-control-label::before {
        //     box-shadow: none;
        // }
    }

    .no-label label {
        display: none;
    }
    .form-group-error {
        .form-control.is-invalid {
            background-image: none;
        }
        .invalid-feedback {
            display: none;
        }
        .input-group-text, .form-control {
            border-color: #fc4141 !important;
        }
    }
    .form-control {
        color: $clr-25 !important;
        font-weight: 700;
        border-color: $clr-89;
        font-family: $LatoHeavy;
        &::-webkit-input-placeholder {
            // WebKit browsers
            color: $clr-89;
            font-weight: 700;
            font-size: 10px;
        }
        &:-moz-placeholder {
            // Mozilla Firefox 4 to 18
            color: $clr-89;
            opacity: 1;
            font-weight: 700;
            font-size: 10px;
        }
        &::-moz-placeholder {
            // Mozilla Firefox 19+
            color: $clr-89;
            opacity: 1;
            font-weight: 700;
            font-size: 10px;
        }
        &:-ms-input-placeholder {
            // Internet Explorer 10+
            color: $clr-89;
            font-weight: 700;
            font-size: 10px;
        }
        &:focus {
            outline: none;
            box-shadow: none !important;
        }
    }
}

.forgot-password-form {
    .login-form-input .form-group:first-child .input-group-text {
        background-image: url(/images/icons/login/Icons-login-email.svg);
        background-position: center center;
        background-repeat:  no-repeat;
        background-size: 12px auto;
    }
    .input-group {
        padding-bottom: 9px;
        &.form-group-error {
            padding-bottom: 5px;
        }
    }
    .form-control {
        padding-top: 5px;
    }
    .invalid-feedback {
        display: block !important;
        background-color: #f7535d;
        color: #fff;
        text-align: center;
        font-weight: 500;
        font-size: 10px;
        padding: 9px 15px;
        margin-top: 13px;
        border-radius: 4px;
        &~.input-group {
            color: red;
        }
    }
}

.confirm-forgot-password {
    color: $clr-gray;
    // text-align: center;
    line-height: 1.2;
    // @include breakpoint(sm) {
    //     margin: -4px -5px -28px;
    // }
    .login-form-logo {
        margin-bottom: 12px;
    }
    .info-email {
        font-size: 12px;
        // font-weight: 500;
        // border-top: 1px solid #e9e9e9;
        // border-bottom: 1px solid #e9e9e9;
        padding: 3px 0 0;
        // margin-bottom: 10px;
        line-height: 1.25;
        color: $clr-89;
        p {
            margin-bottom: 10px;
        }
        h4 {
            font-family: $LatoHeavy;
            font-size: 16px;
            margin-bottom: 0;
        }
        strong {
            font-family: $LatoHeavy;
        }
    }
    .note {}
}
.form-group-password,
.form-group-password-open {
    position: relative;
    .form-control {
        padding-right: 30px !important;
    }
    .toggle-password {
        border: none;
        padding: 0;
        background-color: transparent;
        height: 34px;
        top: 0;
        right: 0;
        background: url(/images/icons/login/Icons-Password-See.svg) no-repeat left center;
        background-size: 15px 12px;
        position: absolute;
        width: 23px;
        z-index: 2;
        cursor: pointer;
        &.active {
            background-image: url(/images/icons/login/Icons-Password-Hide.svg);
        }
    }
}
.login-verify {
    padding-bottom: 55px;
    .login-form-logo {
        margin-bottom: 20px;
    }
    .login-form-input .form-group {
        .input-group-text {
            padding: 0;
            width: 10px;
            background-image: none !important;
        }
        .invalid-feedback {
            background-color: #f7535d;
            color: #fff;
            text-align: center;
            font-weight: 500;
            font-size: 10px;
            padding: 9px 15px;
        }
    }
    
}
.score {
    width: 0;
    height: 4px;
    margin-top: -2px;
    position: relative;
    z-index: 99;
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
    transition: background-color 0.5s ease;
    &.score-4 {
        background-color: red;
        width: 20%;
        border-bottom-left-radius: 3px;
    }
    &.score-3 {
        background-color: orange;
        width: 40%;
        border-bottom-left-radius: 3px;
    }
    &.score-2 {
        background-color: #ffc58b;
        width: 60%;
        border-bottom-left-radius: 3px;
    }
    &.score-1 {
        background-color: yellowgreen;
        width: 80%;
        border-bottom-left-radius: 3px;
    }
    &.score-0 {
        background-color: #78c670;
        width: 100%;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}

.alert-cs {
    background-color: #f7535d;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    padding: 9px 15px;
}
.note-login {
    font-weight: 500;
    font-size: 10px;
    color: $clr-89;
    line-height: 1.25;
    padding-top: 5px;
}

.back-login {
    margin-top: 25px;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 0;
    color: #898989;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: #fd6a14;
    }
}