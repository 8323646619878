.tbl-agents {
    table {
        thead {
            th {
                &:last-child {
                    text-align: center;
                }
            }
            th:nth-child(6) {
                text-align: center !important;
            }
        }
    }
}