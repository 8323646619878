@media print {
    * { 
        overflow: auto !important;
        -webkit-print-color-adjust: exact !important;
        /* Chrome, Safari */
        color-adjust: exact !important;
        background: transparent !important;
        /*Firefox*/
    }
    ::-webkit-scrollbar {
        background: transparent;  /* Optional: just make scrollbar invisible */
        display: none;

    }
    @page 
    {
        size: auto; 
        margin: 5mm;
    }
    .box-template {
        display: contents !important
    }

    .c-sidebar, 
    .nav-tabs,
    .add-lane,
    .btn-sm,
    .dropdown-toggle,
    .delete-icon,
    .btn-fixed,
    .display-class,
    .hXLBYL:after,
    .hXLBYL::before {
        display: none;
    }

    .story-board {
        li div, li, span {
            word-break: break-word !important;
        }
        li div, li, span, h5 {
            color: #333333 !important;
        }
        .react-trello-lane {
            width: fit-content !important;
            min-width: 500px !important;
            overflow: hidden !important;
        }
        .header-print {
            display: block !important;
        }
        .react-trello-board {
            max-height: unset !important;
            height: auto;
            overflow: hidden !important;
            margin-bottom: 70px;
        }
        .tab-content > .tab-pane {
            display: block;
            opacity: 1 !important;
        }

        .smooth-dnd-container.horizontal {
            white-space: unset;
        }
        .box-template {
            .card-header {
                padding-bottom: 0 !important;
                h5 {
                    margin-bottom: 0 !important;
                }
            }
            .items {
                min-height: unset !important;
                small.float-right {
                    display: none;
                }
            }
        }
    }
    .monali-dashboard,
    .dashboard-all {
        .dashboard-print,
        .dashboard-all {
            display: none;
        }
        .dropdown-dashboard-static {
            height: auto;
            opacity: 1;
            display: block;
            background: rgba(14, 37, 56, 0.75) !important;
            .dropdown-header {
                padding-left: 0;
                .num {
                    font-size: 14px;
                }
            }
        }
        .performance-agents {
            .table-performance,
            .list-performance-advisers {
                height: auto;
                max-height: unset;
                thead { display: table-row-group;}
            }
            .btn.dropdown-toggle {
                display: inline;
            }
        }

        .dashboard-static {
            display: inline-block; 
            width: 100%; 
            page-break-inside: avoid;
            margin-bottom: 20px;
        }
    }
    .dashboard-all {
        .block-1 {
            float: left;
            flex: 0 0 50%;
            max-width: 50%;
            vertical-align: text-top;
        }
        &.row {
            display: inline;
            flex-wrap: nowrap;
        }
    }
    .dashboard-print &.print-all {
        display: none;
    }
}

.print-all {
    text-align: right;
    width: 100%;
    display: block;
    margin-bottom: 10px;
}