.card-body-add-templates {
    padding: 40px 55px 58px;
    h3 {
        font-family: Lato-Heavy, sans-serif;
        font-size: 18px;
        color: #252525;
        margin-bottom: 22px;
    }
    .form-control {
        height: 32px;
        border: none;
        background-color: #f2f2f2;
        border-radius: 3px;
        font-weight: 700;
        font-size: 12px;
        color: #252525;
    }
    .form-group {
        margin-bottom: 16px;
    }
    .btn-form-add-user {
        display: flex;
        justify-content: space-between;
        padding-top: 14px;
        .btn {
            border-radius: 30px;
            width: calc(50% - 6px);
        }

    }
}