.card-myhub {
    background-color: #fff;
    border-radius: 3px;
    // padding: 24px;
    min-height: calc(100% - 30px);
    color: #5a5a5a;
}
.card-myhub-header {
    padding: 22px 27px;
}
.info-profile-top {
    display: flex;
    align-items: center;
    background-color: #e4e9e2;
    border-radius: 3px 3px 0 0;
    height: 154px;
    .avatar {
        width: 103px;
        height: 103px;
        border-radius: 50%;
        margin: 0 auto 8px;
        background: rgb(252,109,43);
        background: linear-gradient(0deg, rgba(252,109,43,1) 0%, rgba(249,174,100,1) 100%);
        padding: 3px;
    }
    .avatar-inner {
        background-color: #e4e3e2;
        width: 97px;
        height: 97px;
        border-radius: 50%;
        text-align: center;
        padding: 3px;
        img {
            max-width: 91px;
            max-height: 91px;
            border-radius: 50%;
            vertical-align: middle;
        }
    }
    .info-profile-top-detail {
        width: calc(100% - 100px);
        padding-left: 15px;
    }
    .name {
        font-family: $LatoHeavy;
        margin-bottom: 0;
        font-size: 16px;
        color: #252525;
        line-height: 19px;
        max-height: 38px;
        overflow: hidden;
    }
    .email {
        color: #252525;
        font-size: 12px;
        padding-bottom: 5px;
        margin-bottom: 0;
    }
}
.role-label {
    display: inline-block;
    line-height: 19px;
    border-radius: 2px;
    color: #fff;
    text-align: center;
    min-width: 92px;
    padding: 0 8px;
    font-size: 9px;
    font-weight: 700;
    margin-top: 4px;
    &:not(:last-child) {
        margin-right: 5px;
    }
    &.label-agent {
        background-color: #84c99e;
    }
    &.label-supervisor {
        background-color: #2a9d8f;
    }
    &.label-admin {
        background-color: #287271;
    }
    &.role-lg {
        min-width: 98px;
    }
    &.lbl-client {
        background-color: #e76f51;
    }
    &.lbl-agent {
        background-color: #84c99e;
    }
    &.lbl-intern {
        background-color: #f4a261;
    }
    &.lbl-supervisor {
        background-color: #2a9d8f;
    }
}
.fw-600 {
    font-weight: 600;
}
.fw-500 {
    font-weight: 500;
}
.fw-700 {
    font-weight: 700;
}
.body-card-hub {
    font-family: $LatoHeavy;
    padding: 0;
    color: #5a5a5a;
    table {
        margin-bottom: 0;
        font-weight: 700;
        color: #5a5a5a;
        td {
            vertical-align: middle;
            padding-top: 14px;
            padding-bottom: 13px;
            &:first-child {
                padding-left: 27px;
                font-size: 12px;
            }
            &:last-child {
                padding-right: 27px;
                font-size: 14px;
            }
        }
        tr {
            &:nth-child(2n+2) {
                background-color: #f9f9fb;
            }
            &.active {
                td {
                    &:first-child {
                        position: relative;
                        &::before {
                            content: "";
                            display: block;
                            width: 6px;
                            height: 6px;
                            background-color: #00a453;
                            border-radius: 50%;
                            position: absolute;
                            left: 10px;
                            top: 50%;
                            transform: translate(0,-50%);
                        }
                    }
                }
            }
        }
    }
    hr {
        margin: 0;
        border-top-color: #e0e0e0;
    }
    .fz-12 {
        font-size: 12px;
        font-family: $LatoHeavy;
    }
    .fz-10 {
        p {
            font-size: 10px;
            margin-bottom: 0;
        }
    }
}
.footer-card-hub {
    padding: 20px;
    min-height: 60px;
}
.label-progress {
    display: inline-block;
    &::before {
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        vertical-align: 1px;
        margin-right: 5px;
    }
    &.label-green {
        color: #3cb953;
        &::before {
            background-color: #3cb953;
        }
    }
}
.static-profile-top {
    padding: 22px 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $LatoHeavy;
    font-size: 14px;
    border-radius: 3px 3px 0 0;
    color: #f06d54;
    height: 98px;
    background: rgb(228,226,225);
    background: linear-gradient(90deg, rgba(228,226,225,1) 0%, rgba(239,215,204,1) 100%);
    // &.bg-1 {
    //     background: rgb(101,218,163);
    //     background: linear-gradient(90deg, rgba(101,218,163,1) 0%, rgba(81,208,214,1) 100%);
    // }
    // &.bg-2 {
    //     background: rgb(248,198,77);
    //     background: linear-gradient(90deg, rgba(248,198,77,1) 0%, rgba(253,147,69,1) 100%);
    // }
    .txt {
        line-height: normal;
        font-weight: 600;
    }
    .number {
        font-size: 32px;
        font-family: $LatoHeavy;
    }
}
.info-profile-detail {
    .btn {
        min-width: 170px;
        // margin-top: 25px;
        color: #f0662f;
        font-weight: 600;
        font-size: 10px;
        padding: 0;
    }
    table {
        tbody {
            tr {
                &:last-child {
                    td {
                        vertical-align: top;
                        .text-link {
                            color: #f0662f
                        }
                    }
                }
            }
        }
    }
}
.static-profile-detail {
    min-height: calc(100% - 158px);
    .table {
        td {
            height: 60px;
            padding-top: 10px;
            padding-bottom: 9px;
            &:first-child {
                border-right: 1px solid #ccc;
            }
            &:last-child {
                width: 125px;
            }
        }
    }
}
.mb-30 {
    margin-bottom: 30px;
}

.list-table-dark {
    font-size: 12px;
    font-family: $LatoHeavy;
    text-align: center;
    .table {
        margin-bottom: 0;
        thead {
            th {
                background-color: #242223;
                color: #fff;
                &.td-bg-gray {
                    background-color: #7a7a7a;
                }
                &:first-child {
                    border-radius: 3px 0 0 0;
                }
                &:last-child {
                    border-radius: 0 3px 0 0;
                }
                &:nth-child(2) {
                    border-right: 1px solid #878787;
                }
            }
        }
        tbody {
            tr {
                td {
                    border: none;
                    border-right: 1px solid #ccc;
                    background-color: #fffdfe;
                    &.td-bg-blue {
                        background-color: #d8eaf8;
                    }
                    &:nth-child(2) {
                        border-right: 1px solid #878787;
                    }
                    &:last-child {
                        border-right: none;
                    }
                }
                &:nth-child(2n+1) {
                    td {
                        background-color: #eaeaea;
                        &.td-bg-blue {
                            background-color: #d8eaf8;
                        }
                    }
                }
                &:last-child {
                    td {
                        &:first-child {
                            border-radius: 0 0 0 3px;
                        }
                        &:last-child {
                            border-radius: 0 0 3px 0;
                        }
                    }
                }
            }
        }
    }
}
.ml-10 {
    margin-left: 10px;
}
.react-datepicker-wrapper {
    input::-webkit-inner-spin-button,
    input::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
}
.text-primary {
    color: #fc6a28 !important;
}
.text-bold {
    font-weight: 700;
}
.td-300 {
    width: 300px;
}
.box-content-avatar {
    display: flex;
    align-items: center;
    .avatar-circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $clr-white;
        img {
            border-radius: 50%;
            width: 30px;
            height: 30px;
        }
    }
    .text {
        width: calc(100% - 30px);
        padding-left: 15px;
    }
}
.list-table-myhub {
    .table {
        thead {
            th {
                font-size: 10px;
                text-align: center;
                border: 1px solid #d8dbe0;
            }
        }
        tbody {
            td {
                font-weight: 500;
                font-size: 14px;
                border: 1px solid #d8dbe0;
                &:last-child {
                    font-weight: 700;
                    border-left: 1px solid #ccc;
                }
            }
        }
    }
}

.my-campagne-report {
    .card-header {
        border: none
    }
}

.card-tab {
    .tab-content-cs {
        padding-bottom: 70px;
    }
    .nav-tabs-cs .nav-link {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.tippy-box, .tippy-content, .tippy-arrow  {
    background-color: #373737 !important;
}
.tippy-arrow {
    color: #373737 !important;
}
.tippy-content {
    font-size: 12px;
    min-width: 78px;
}