.card {
    border: none;
}

.title-page {
    text-transform: uppercase;
    font-size: 14px;
    font-family: $LatoHeavy;
    margin: 0 0 13px;
    color: #252525;
}

.table-display-log {
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 12px;
    font-weight: 500;
    color: #5a5a5a;
    thead {
        tr {
            color: #ffffff;
            font-weight: 500;
            font-size: 11px;
            th {
                background-color: #373737;
                border: none;
                padding: 4px 14px;
                &.table-log-title {
                    text-transform: uppercase;
                    font-size: 12px;
                    font-family: $LatoHeavy;
                    color: #252525;
                    text-align: left;
                    background-color: #fff;
                    border-top: 1px solid #e5e5e5;
                    padding: 12px 0 5px;
                }
            }
        }
    }

    tbody {
        tr {
            background-color: #ffffff;

            td {
                border: none;
                border-left: 1px solid #cccccc;
                padding: 10px 14px 9px;
                &:first-child, &:nth-child(2) {
                    border-left: none;
                }
                &:first-child {
                    background-color: rgba(#ccc,0.48);
                    font-family: $LatoHeavy;
                }
                ul {
                    margin-bottom: 0;
                    padding-left: 15px;
                }
            }

            &:nth-child(2n+1) {
                background-color: #f9f9fb;
            }
        }
    }

    .allow-click {
        cursor: pointer;
        color: #65aecc;

        &:hover {
            color: #fd6a14;
        }
    }
}

.log-page {
    label.action {
        padding: 3px 8px;
        color: #ffffff;
        font-size: 10px;
        border-radius: 5px;

        &.create {
            background-color: rgba(90, 181, 0, 0.693);
        }

        &.update {
            background-color: #65aecc;
        }

        &.export {
            background-color: #da5700;
        }

        &.delete {
            background-color: rgb(177, 0, 35);
        }
    }
    span {
        &.export-field {
            background-color: #e6e6e6;
            color: #000;
            padding: 3px 10px;
            margin: 2px;
            border-radius: 2px;
            font-size: 85%;
            float: left;
        }
    }
}

.card-log-detail {
    .card-header {
        background-color: #fd6a14;
        color: #ffffff;
        text-transform: uppercase;
    }

    .table-log-detail {
        width: 100%;
        max-width: 100%;
        text-align: left;

        thead {
            tr {
                color: #ffffff;

                th {
                    text-align: center;
                    padding: 5px;
                    border: 1px solid #686868;
                    background-color: #686868;
                    border-bottom-width: 2px;
                    min-width: 80px;

                    &.table-log-title {
                        text-transform: uppercase;
                        background-color: #fd6a14;
                        border: 1px solid #fd6a14;
                        height: 35px;
                        line-height: 35px;
                    }
                }
            }
        }

        tbody {
            tr {
                color: #000;
                background-color: #ffffff;

                td {
                    border: 1px solid #ddd;
                    padding: 5px;
                    overflow-wrap: anywhere;
                    max-width: 100%;

                    thead {
                        tr {
                            color: #ffffff;
                        }
                    }

                    tbody {
                        tr {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                }

                &:nth-child(2n) {
                    background-color: #eee;
                }
            }
        }
    }
}

.log-details {
    span {
        padding: 3px 8px;
        color: #ffffff;
        font-size: 10px;
        border-radius: 5px;
        &.action-type {
            min-width: 98px;
            display: inline-block;
            text-align: center;
            font-size: 9px;
            font-weight: 700;
            border-radius: 3px;
        }
        &.create-label {
            background-color: rgba(90, 181, 0, 0.693);
        }

        &.update-label {
            background-color: #2a9d8f;
        }

        &.export-label {
            background-color: #da5700;
        }

        &.delete-label {
            background-color: rgb(177, 0, 35);
        }

        &.dons-label {
            background-color: #84c99e;
        }

        &.reset-label {
            background-color: #e76f51;
        }

        &.send-mail-label {
            background-color: #f4a261;
        }

        &.login-label {
            background-color: #f2be38;
        }

        &.change-password-label {
            background-color: #f26621;
        }
    }
}

.nav-tabs-cs {
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 18px 0 !important;
    position: relative;
    .nav-item {
        &:not(:last-child) {
            margin-right: 26px;
        }
    }
    .nav-link {
        border: none;
        border-radius: 0;
        text-transform: uppercase;
        color: #5a5a5a;
        font-family: $LatoHeavy;
        font-size: 11px;
        border-bottom: 4px solid transparent;
        padding: 13px 4px;
        &.active,
        &:hover {
            color: $clr-main;
            background-color: transparent;
        }
        &:hover {
            border-bottom-color: transparent;
        }
        &.active {
            border-bottom-color: $clr-main;
        }
    }
}
.mr-10 {
    margin-right: 10px;
}
.tab-content-cs, .card-common {
    .card-header {
        padding: 15px 14px 1px 24px;
    }
    label.icon.iconCalendar.calendar {
        background: url(/images/icons/Icons-calendar.svg) no-repeat center center;
        background-size: 12px 12px;
        position: absolute;
        right: 7px;
        top: 0;
        bottom: 0;
        margin: 0;
        svg {
            opacity: 0;
        }
    }
    .filters-user {
        .row-xs {
            margin-left: -5px;
            margin-right: -5px;
            .col {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        .form-control {
            height: 25px;
            border-radius: 3px;
            background-color: #f2f2f2;
            font-size: 10px;
            font-weight: 400;
            padding: 0 8px 2px;
            color: #5a5a5a;
            max-width: 200px;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
        #byAction,
        #byDonor,
        #byCampagne {
            min-width: 126px;
        }
    }
    .filter-department {
        .filter-date {
            height: 25px;
            border-radius: 3px;
            background-color: #f2f2f2;
            font-size: 10px;
            font-weight: 400;
            border: none;
            padding: 0 8px 2px;
            color: #5a5a5a;
            min-width: 107px;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .search-user {
        .form-control {
            height: 25px;
            border-radius: 15px;
            background-color: #f2f2f2;
            font-size: 10px;
            font-weight: 400;
            border: none;
            padding: 0 12px 2px;
            color: #5a5a5a;
            @include placeholder(#5a5a5a);
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .label-filter {
        color: #5a5a5a;
        font-size: 10px;
        font-weight: 600;
    }
    .label-display-by {
        font-size: 10px;
        color: #5a5a5a;
        margin: 0 10px 0 0;
        &::before {
            display: none;
        }
        label {
            padding-top: 3px;
        }
        .form-group {
            padding-left: 22px;
            margin-top: -4px;
            position: relative;
            .custom-control-label {
                &:before,
                &:after {
                    left: -22px !important;
                }
            }
        }
    }
}
.card-common {
    .card-header {
        padding-left: 16px;
    }
}
.custom-control-label {
    &::before {
        border-color: #acacac;
    }
}
.custom-control-input:checked~.custom-control-label::before {
    background-color: #fc6a28;
    border-color: #fc6a28;
}
.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #fc6a28;
}
.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(#fc6a28, 0.25);
}
.list-table-common {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            &:not(:last-child) {
                margin-bottom: 4px;
            }
            .badge {
                font-weight: 900;
                font-size: 10px;
                padding: 3px 4px 5px;
                min-width: 87px;
            }
        }
        &.item-of-list {
            list-style: initial;
            margin-left: 12px;
        }
        &.item-of-list-inline {
            list-style: none;
            li {
                display: inline-block;
                &:not(:last-child) {
                    &::after {
                        content: "|";
                        margin: 0 5px;
                    }
                }
            }
        }
    }
    span.action-type {
        padding: 3px 8px;
        color: #ffffff;
        font-size: 9px;
        font-weight: 700;
        border-radius: 3px;
        min-width: 98px;
        display: inline-block;
        text-align: center;
        &.create-label {
            background-color: rgba(90, 181, 0, 0.693);
        }
        &.update-label {
            background-color: #2a9d8f;
        }
        &.export-label {
            background-color: #da5700;
        }
        &.delete-label,
        &.failed-label {
            background-color: rgb(177, 0, 35);
        }
        &.dons-label {
            background-color: #84c99e;
        }
        &.reset-label {
            background-color: #e76f51;
        }
        &.loto-label,
        &.success-label {
            background-color: #30a9ea;
        }
        &.send-mail-label {
            background-color: #f4a261;
        }
        &.login-label {
            background-color: #f2be38;
        }
        &.change-password-label {
            background-color: #f26621;
        }
    }
    .svg-small {
        svg {
            width: 16px;
            height: 16px;
        }
    }
    .btn-delete-user {
        color: #5a5a5a;
        &:hover,
        &:focus {
            color: #fc6a28 !important;
        }
    }
    table {
        margin-bottom: 0;
        border-bottom: 1px solid #e5e5e5;
        font-size: 10px;
        font-weight: 500;
        color: #5a5a5a;
        td,
        th {
            border-color: #e5e5e5;
            vertical-align: middle;
            &:first-child {
                padding-right: 0;
                padding-left: 15px;
            }
        }
        th {
            padding: 9px 10px;
            background-color: #fff;
            color: #5a5a5a;
            font-weight: 700;
            font-size: 11px;
            // border-width: 1px;
            border: none;
        }
        tbody {
            tr {
                background-color: #fff !important;
                &:first-child {
                    td {
                        position: relative;
                        &::after {
                            // width: 100%;
                            content: "";
                            display: block;
                            left: -1px;
                            right: 0;
                            top: 0;
                            background: url(/images/bg-top-table.png) top left repeat-x;
                            height: 8px;
                            position: absolute;
                            // box-shadow: 1px 5px 8px rgba(#898989,0.5);
                            // background-color: rgba(#898989,0.5);
                        }
                    }
                }
                &:nth-child(2n+1) {
                    background-color: #f9f9fb !important;
                }
                &:hover {
                    td {
                        &:first-child {
                            position: relative;
                            &:before {
                                content: "";
                                display: block;
                                width: 3px;
                                height: 100%;
                                left: 0;
                                top: 0;
                                background: rgb(242, 116, 66);
                                background: -moz-linear-gradient(0deg, rgba(242, 116, 66, 1) 0%, rgba(249, 174, 114, 1) 100%);
                                background: -webkit-linear-gradient(0deg, rgba(242, 116, 66, 1) 0%, rgba(249, 174, 114, 1) 100%);
                                background: linear-gradient(0deg, rgba(242, 116, 66, 1) 0%, rgba(249, 174, 114, 1) 100%);
                                //filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#f27442", endColorstr="#f9ae72", GradientType=1);
                                position: absolute;
                            }
                        }
                    }
                }
                td {
                    border: none;
                    border-left: 1px solid #f2f2f2;
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(2) {
                        border-left: none;
                    }
                    &.allowPointer {
                        cursor: pointer !important;
                        &:hover {
                            color: #fc6a28;
                        }
                    }
                    &.td-heavy {
                        font-family: $LatoHeavy;
                        font-size: 12px;
                    }
                    &.td-semi {
                        font-weight: 600;
                        font-size: 12px;
                    }
                    &.role-only {
                        span {
                            padding: 3px 8px;
                            font-size: 9px;
                            font-weight: 700;
                            border-radius: 3px;
                            min-width: 98px;
                            display: inline-block;
                            text-align: center;
                            line-height: 13.5px;
                        }
                    }
                    &:first-child {
                        background-color: rgba(#ccc, 0.3);
                        //     // &::after {
                        //     //     background-image: url(/images/bg-top-table-part-1.png);
                        //     // }
                    }
                    &.td-bg-opacity {
                        background-color: rgba(#ccc, 0.3);
                        &+.td-bg-opacity {
                            border-left: 1px solid #fff;
                        }
                        &+.td-nom {
                            border-left: none;
                        }
                    }
                    &.no-border-left {
                        border-left: none;
                    }
                    span {
                        &.level-high {
                            color: #ff5100;
                        }
                        &.level-medium {
                            color: #dbc501;
                        }
                        &.level-low {
                            color: #929292;
                        }
                        &.level-label {
                            padding: 3px 8px;
                            color: #ffffff;
                            font-size: 9px;
                            font-weight: 700;
                            border-radius: 3px;
                            min-width: 98px;
                            display: inline-block;
                            text-align: center;
                            &.level-high {
                                background-color: #fc515c;
                            }
                            &.level-medium {
                                background-color: #f4a261;
                            }
                            &.level-low {
                                background-color: #898989;
                            }
                        }
                    }
                }
            }
        }
        .td-checkbox {
            width: 28px;
        }
        .action {
            white-space: nowrap;
            // text-align: center;
            width: 85px;
            a {
                color: #c8c8c8;
                &:hover {
                    color: #fd6a14;
                }
                &:disabled,
                &.disabled {
                    cursor: not-allowed;
                    pointer-events: none;
                }
            }
        }
        .td-122 {
            width: 122px;
        }
        .td-160 {
            width: 160px;
        }
        .td-100 {
            width: 100px;
        }
        .td-65 {
            width: 65px;
        }
        .td-84 {
            width: 84px;
        }
        .custom-control-inline {
            margin-right: 0;
        }
        .custom-control {
            padding-left: 16px;
            margin-top: 4px;
            .custom-control-label {
                &:before,
                &:after {
                    left: -16px !important;
                }
            }
        }
        .td-90 {
            width: 90px;
        }
    }
    .type {
        .badge {
            min-width: 87px;
            line-height: 19px;
            font-size: 10px;
            padding: 0 5px;
            font-weight: 900;
        }
    }
    .ambassador {
        color: #f28b05;
        .badge {
            color: #f28b05;
            background-color: #f4d5b5;
        }
    }
    .director {
        color: #9f8cdc;
        .badge {
            color: #9f8cdc;
            background-color: #efecfc;
        }
    }
    .vice-director {
        color: #cbc042;
        .badge {
            color: #cbc042;
            background-color: #fbf6c4;
        }
    }
    .super-admin {
        color: #fe8b90;
        .badge {
            color: #fe8b90;
            background-color: #f7c9cb;
        }
    }
    .controller {
        color: #65aecc;
        .badge {
            color: #65aecc;
            background-color: #cfeaf5;
        }
    }
    .advisor {
        color: #43aa8c;
        .badge {
            color: #43aa8c;
            background-color: #d9f3e8;
        }
    }
    .advisor {
        color: #60aca7;
        .badge {
            color: #60aca7;
            background-color: #afddda;
        }
    }
    .type-dons {
        color: #fd6a14;
    }
    .content-user-bottom {
        padding: 0 15px 15px;
        .btns-group-bottom {
            margin-left: 0;
        }
    }
}

.ico-overview {
    display: inline-block;
    background: url(/images/icons/Icons-Logs-overview.svg);
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    &:hover {
        background-image: url(/images/icons/Icons-Logs-overview-hover.svg);
    }
}
.ico-detail-summary {
    display: inline-block;
    background: url(/images/icons/Icons-Details-summary.svg);
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    &:hover {
        background-image: url(/images/icons/Icons-Details-summary-o.svg);
    }
}
.ico-trash {
    display: inline-block;
    background: url(/images/icons/Icons-trash.svg);
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    &:hover {
        background-image: url(/images/icons/Icons-trash-hover.svg);
    }
}
.ico-grid {
    display: inline-block;
    background: url(/images/icons/ico-grid.png);
    width: 13px;
    height: 13px;
    background-size: 13px 13px;
    &:hover {
        background-image: url(/images/icons/ico-grid-o.png);
    }
}

.ico-boilerplate {
    display: inline-block;
    background: url(/images/icons/Icons-Boilerplate-cccccc.svg);
    width: 13px;
    height: 13px;
    background-size: 13px 13px;
    &:hover {
        background: url(/images/icons/Icons-Boilerplate-cccccc-o.svg);
    }
}
.ico-archiver {
    display: inline-block;
    background: url(/images/icons/Icons-Archiver.svg);
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    &:hover {
        background: url(/images/icons/Icons-Archiver-o.svg);
    }
}
.ico-environment {
    display: inline-block;
    background: url(/images/icons/Icons-actions-computer-spec.svg);
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    &:hover {
        background-image: url(/images/icons/Icons-actions-computer-spec-hover.svg);
    }
}
.ico-trash {
    display: inline-block;
    background: url(/images/icons/Icons-actions-trash.svg);
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    &:hover {
        background-image: url(/images/icons/Icons-actions-trash-hover.svg);
    }
}
.logs-meta {
    color: #5a5a5a;
    margin-bottom: 10px;
    .action {
        margin-bottom: 10px;
    }
    .date-author {
        span {
            color: #5a5a5a;
            font-size: 12px;
            font-weight: 700;
            padding: 0;
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
    .url-link {
        a {
            text-decoration: underline;
            color: #fc6a28;
            font-weight: 600;
            font-size: 12px;
        }
    }
}
.btn-circle {
    border-radius: 50%;
    background-color: #f2f2f2;
    width: 32px;
    height: 32px;
    text-align: center;
    color: #acacac;
    font-weight: 700;
    padding: 0;
    text-align: center;
    line-height: 32px;
    svg {
        margin: 0 !important;
        vertical-align: -1px;
    }
    svg, polygon {
        fill: #acacac;
    }
    &:hover {
        background-color: #ededed;
        color: #898989;
        svg, polygon {
            fill: #898989;
        }
    }
    &:focus {
        background-color: #fc6a28;
        color: #fff;
        svg, polygon {
            fill: #fff;
        }
    }
}
.table-sticky {
    overflow: hidden;
    thead {
        position: sticky;
        top: 0;
        z-index: 2;
        tr {
            background-color: #fff;
        }
    }
    .table-responsive {
        max-height: 600px;
        // width: calc(100% + 20px);
        // &::-webkit-scrollbar {
        //     height: 8px;
        //     border: none;
        // }
          
        // &::-webkit-scrollbar-track:horizontal {
        //     border-radius: 0;
        //     background: red;
        // }
          
        // &::-webkit-scrollbar-thumb:horizontal {
        //     border-radius: 0;
        //     background: transparent;
        // }
    }
}
.pb-30 {
    padding-bottom: 30px;
}
.status-completed {
    color: #2a9d8f;
}
.status-in-progress {
    color: #84c99e;
}
.status-preperation {
    color: #fc515c;
}
.status-archive {
    color: #ffd230;
}
.status-end {
    color: #616161;
}
.card-logs {
    .filter-date {
        background: none;
    }
}
.max-285 {
    max-width: 285px;
}

.ico-comment {
    display: inline-block;
    background: url(/images/icons/Icons-Comments.svg);
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    &:hover {
        background-image: url(/images/icons/Icons-Comments-o.svg);
    }
}