.ico-check-gray {
    background: url(/images/icons/03-Icons-check.svg) no-repeat;
    background-size: 16px 16px;
    display: inline-block;
    width: 16px !important;
    height: 16px !important;
    margin: 0 !important;
}
.ico-revert {
    background: url(/images/icons/Icons-Revert.svg) no-repeat;
    background-size: 16px 16px;
    display: inline-block;
    width: 16px !important;
    height: 16px !important;
    margin: 0 !important;
}

.support-form {
    .ticket-box {
        position: relative;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 50px;
        border-radius: 5px;
        padding: 10px 0;

        &.open-ticket {
            background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(50, 209, 1), rgb(46, 190, 3));

        }

        &.close-ticket {
            background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(90, 90, 90), rgb(90, 90, 90));
        }

        .title {
            font-size: 20px;
            font-weight: 700;
            padding-right: 145px;
        }

        .createdAt {
            font-size: 12px;
            font-style: italic;
        }

        .status {
            position: absolute;
            top: 21px;
            right: 15px;
            color: #fff;
            text-align: center;

            span {
                margin-top: 15px;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 700;
            }
        }
    }

    .box-message {
        max-width: 1000px;
        margin: 0 auto 18px;
        &.is-border {
            border: 1px solid #b0b0b0;
            border-radius: 3px;
        }
        .avatar {
            display: flex;
            align-items: center;
            color: #ff6f1b;
            font-weight: 700;
            .icon {
                display: block;
                width: 31px;
                height: 31px;
                color: #fff;
                text-align: center;
                line-height: 31px;
                background-color: #fe8b90;
                font-family: Lato-Heavy, sans-serif;
                font-size: 14px;
                border-radius: 50%;
                margin-right: 10px;
            }
        }

        .message {
            .card-header {
                background-color: #dddddd;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .card-body {
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .image-attach {
                img {
                    max-width: 80px;
                    height: auto;
                    border: 3px solid #dddddd;
                    border-radius: 5px;
                    &:hover  {
                        border: 3px solid #b9b9b9;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.btn-add-user {
    &.is-icon {
        border-radius: 50%;
        background-color: #f2f2f2;
        width: 32px;
        height: 32px;
        text-align: center;
        margin-bottom: 14px;
        color: #acacac;
        font-weight: 700;
        svg, polygon {
            fill: #acacac;
        }
        &:hover {
            background-color: #ededed;
            color: #898989;
            svg, polygon {
                fill: #898989;
            }
        }
        &:focus, &:active {
            background-color: #fc6a28;
            color: #fff;
            svg, polygon{
                fill: #fff;
            }
        }
    }
    &.is-absolute {
        position: absolute;
        top: 12px;
        right: 12px;
    }
}

.list-table-common {
    &.table-support {
        table {
            font-family: Lato, sans-serif;
            td {
                border-left-color: #efefef !important;
                &:last-child {
                    width: 60px;
                    text-align: center;
                }
            }
        }
        .role-label {
            padding: 3px 8px;
            color: #ffffff;
            font-size: 9px;
            font-weight: 700;
            border-radius: 3px;
            min-width: 98px;
            display: inline-block;
            text-align: center;
            &.all {
                background-color: #8660a9;
            }
            &.agent {
                background-color: #83ca9e;
            }
            &.intern {
                background-color: #f6a25a;
            }
            &.supper {
                background-color: #219e90;
            }
            &.client {
                background-color: #15897c;
            }
        }
    }
}
.ticket-modal {
    &.modal-new-style {
        .modal-title {
            max-width: 200px;
        }
    }
    .no-label {
        label {
            display: none;
        }
    }
}
.support-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
        font-size: 20px;
        font-weight: 700;
    }
}
.support-form-header-right {
    text-align: right;
    .status {
        font-weight: 700;
        &.open-ticket {
            color: #ff6f1b;
        }
    }    
}
.support-form {
    .createdAt {
        font-size: 11px;
    }
}
.block-mess {
    border-radius: 3px;
    // margin-right: 15px;
    // padding: 5px 10px;
    padding: 10px 15px;
    margin: -5px 10px -5px -5px;
    text-align: left;
    span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
        font-family: $LatoHeavy;
    }
}
.picker-color-inline {
    .input-picker-color, label {
        display: inline-block;
        vertical-align: middle;
    }
    label {
        color: #252525;
        font-weight: 600;
        margin: 0;
    }
    .col-md-12 {
        direction: rtl;
    }
    .input-picker-color {
        margin-right: 5px;
        span {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            padding: 2px;
            span {
                width: 14px;
                height: 14px;
                border: none;
            }
        }
    }
}
.modal-new-style {
    &.add-noti-modal {
        .modal-lg {
            @include breakpoint(md) {
                max-width: 500px;
            }
        }
        .modal-header {
            margin-bottom: 25px;
        }
        .modal-body-padding {
            padding-bottom: 14px;
            padding-right: 45px;
            padding-left: 45px;
        }
        .modal-footer-cs {
            display: block;
            padding-left: 45px;
            padding-right: 45px;
            .row {
                margin-left: -4px;
                margin-right: -4px;
                .col-6 {
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }
        .form-control, .react-select {
            font-size: 11px;
            color: #252525;
        }
        .form-control {
            height: 38px;
        }
        textarea.form-control {
            height: 100px;
            overflow-x: hidden;
            overflow-y: auto;
        }
        .no-label {
            label {
                display: none;
            }
        }
        .react-datepicker-popper {
            z-index: 17;
        }
    }
}
.filter-date:placeholder-shown {
    background: url(/images/icons/Icons-calendar.svg) calc(100% - 5px) center no-repeat;
    background-size: 16px 16px;
}


.ml-10 {
    margin-left: 10px;
}
.ico-grid {
    display: inline-block;
    background: url(/images/icons/ico-grid.png);
    width: 13px;
    height: 13px;
    background-size: 13px 13px;
    &:hover {
        background-image: url(/images/icons/ico-grid-o.png);
    }
}
.td-65 {
    width: 65px;
}
.td-90 {
    width: 90px;
}
.td-75 {
    width: 75px;
}