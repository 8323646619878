.ico-back-page {
    display: inline-block;
    background-image: url(/images/icons/Icons-close-page.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 20px;
    height: 20px;
}
.transfer-page {
    display: flex;
    min-height: 100vh;
    margin-top: -32px;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    @include breakpoint(md) {
        margin-left: -30px;
        margin-right: -30px;
    }
}
.transfer-sidebar {
    background-color: #fff;
    width: percentage(382/1228);
    min-height: 100%;
    box-shadow: 0 0 10px rgba(#000,0.25);
    padding: 16px 12px 20px;
    min-width: 380px;
    max-width: 380px;
}
.transfer-sidebar-toggle {
    // display: none;
    position: absolute;
    left: -256px;
    opacity: 0;
    transition: all ease 0.3s;
    &.show {
        left: 0;
        opacity: 1;
    }
}
.title-transfer {
    font-size: 14px;
    font-family: $LatoHeavy;
    color: $clr-25;
}
.content-transfer-sidebar {
    padding: 38px 32px;
    .form-control {
        color: #5a5a5a;
        &::placeholder {
            color: #5a5a5a;
        }
    }
}
.process-download {
    position: relative;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-bottom: 12px;
    .inner {
        background-color: #ededed;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        text-align: center;
        line-height: 102px;
        overflow: hidden;
        position: absolute;
        top: 5px;
        left: 5px;
        img {
            vertical-align: middle;
        }
    }
}
.font-heavy {
    font-family: $LatoHeavy;
}
.step-notice {
    h4 {
        font-size: 18px;
        color: $clr-25;
        margin-bottom: 2px;
    }
    .desc {
        font-size: 14px;
        color: $grey-1;
        margin-bottom: 25px;
    }
}
.step-download {
    .btn-warning:disabled {
        background: rgba(240, 102, 47, 1);
        background: -moz-linear-gradient(left, rgba(240, 102, 47, 1) 0%, rgba(246, 148, 49, 1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(240, 102, 47, 1)), color-stop(100%, rgba(246, 148, 49, 1)));
        background: -webkit-linear-gradient(left, rgba(240, 102, 47, 1) 0%, rgba(246, 148, 49, 1) 100%);
        background: -o-linear-gradient(left, rgba(240, 102, 47, 1) 0%, rgba(246, 148, 49, 1) 100%);
        background: -ms-linear-gradient(left, rgba(240, 102, 47, 1) 0%, rgba(246, 148, 49, 1) 100%);
        background: linear-gradient(to right, rgba(240, 102, 47, 1) 0%, rgba(246, 148, 49, 1) 100%);
        border: none;
        opacity: 0.5;
    }
}
.title-step {
    font-size: 18px;
    color: $clr-25;
    border-bottom: 1px solid #cbcbcb;
    padding-bottom: 19px;
    margin-bottom: 14px;
}
.content-step-download {
    padding-bottom: 18px;
    .no-data {
        color: $grey-1;
        font-size: 12px;
        font-weight: 500;
        border-bottom: 1px solid #cbcbcb;
        padding-bottom: 15px;
    }
}
.list-transfer-download {
    margin: 0;
    &+.form-upload {
        margin-top: 15px;
    }
    li {
        position: relative;
        padding-left: 14px;
        &:not(:first-child) {
            margin-top: 2px;
        }
        &::before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background-color: $grey-1;
            border-radius: 0;
            position: absolute;
            top: 6px;
            left: 0;
            border-radius: 50%;
        }
        .txt {
            margin-right: 4px;
        }
    }
}
.sidebar-back {
    margin-bottom: 29px;
}
.transfer-content {
    padding: 63px 17px 40px;
    transition: all ease 0.3s;
    .title-transfer {
        margin-bottom: 13px;
    }
    .card-header {
        min-height: 57px;
    }
    .list-table-common {
        // padding-top: 48px;
        // padding-bottom: 55px;
        table {
            font-size: 12px;
            max-width: 100%;
            table-layout: fixed;
            tbody {
                tr {
                    cursor: pointer;
                    &:nth-child(2n+1) {
                        background-color: #f9f9fb !important;                        
                    }
                    &:nth-child(2n+2) {
                        background-color: #fff !important;
                    }
                    td {
                        background-color: transparent;
                        font-weight: 400;
                        &:first-child, &:nth-child(2) {
                            background-color: rgba(#ccc,0.3);
                        }
                    }
                    .btn-delete:hover {
                        color: $primary-1;
                        .ico-trash {
                            background-image: url(/images/icons/Icons-actions-trash-hover.svg);
                        }
                    }
                    .dropdown-toggle:hover {
                        color: #ff6f1b;
                    }
                }
            }
            .font-medium {
                font-size: 10px;
            }
        }
    }
    .w-78 {
        width: 78px;
    }
    .w-98 {
        width: 98px;
    }
    .w-40 {
        width: 40px;
    }
}
.transfer-content-collapse {
    &.show {
        margin-left: 380px;
    }
}
.font-semibold {
    font-weight: 600;
}
.font-medium {
    font-weight: 500;
}
.text-decoration-underline {
    text-decoration: underline;
}
.text-ellipsis {
    text-overflow: ellipsis;
}
.ico-upload {
    background: url(/images/icons/Icons-Overview-Upload.svg) no-repeat;
    width: 12px;
    height: 15px;
    background-size: 100% 100%;
    display: inline-block;
}
.ico-download {
    background: url(/images/icons/Icons-transfert-download.svg) no-repeat;
    width: 12px;
    height: 15px;
    background-size: 100% 100%;
    display: inline-block;
}
.ico-close-file {
    background: url(/images/icons/ico-close-file.png) no-repeat;
    width: 15px;
    height: 15px;
    background-size: 100% 100%;
    display: inline-block;
}
.btn-add-user.is-icon {
    &:focus {
        .ico-upload {
            background-image: url(/images/icons/Icons-Overview-Upload-White.svg);
        }
    }
    &.add-icon {
        background-color: #fdab54;
        border: none;
        &::-webkit-file-upload-button {
            visibility: hidden;
        }
        &:before {
            content: '+';
            outline: none;
            white-space: nowrap;
            color: #fff;
            cursor: pointer;
            font-weight: 700;
            font-size: 14pt;
            padding: 2px 11px;
            display: inline-block;
        }
    }
}
.choose-file-upload {
    display: flex;
    align-items: center;
    .desc {
        padding-left: 12px;
        color: #5a5a5a;
        strong {
            font-weight: 900;
        }
    }
    &.has-file {
        border-bottom: 1px solid $grey-2;
        padding-bottom: 10px;
        margin-bottom: 13px;
    }
}
.btn-upload-file {
    width: 32px;
    flex: 0 0 32px;
    max-width: 32px;
    button {
        color: #fff;
        svg, polygon {
            fill: #fff !important;
        }
    }
}
.form-upload {
    margin-top: 20px;
    .form-group {
        margin-bottom: 15px;
    }
    input, textarea, select {
        background-color: #f2f2f2;
        border: none;
        border-radius: 3px;
        font-size: 10px;
        font-weight: 700;
        padding: 8px 10px;
        &:focus {
            outline: none;
            box-shadow: none;
            background-color: #f2f2f2;
        }
    }
    input, select {
        height: 33px;
    }
    textarea {
        height: 74px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}
textarea {
    resize: none;
}

.list-file-upload {
    border-top: 1px solid #cbcbcb;
    margin-top: 10px;
    padding: 10px;

    .delete-file {
        display: inline-block;
        width: 13px;
        height: 13px;
        background: url(/images/close.png) no-repeat;
        background-size: 13px 13px;
        vertical-align: middle;
        margin-left: 5px;
        cursor: pointer;
        opacity: .5;
    }
}

.table-transfer {
    thead {
        th {
            &:first-child {
                width: 40px;
            }
            &:last-child {
                width: 80px;
            }
            &:nth-last-child(2) {
                width: 100px;
            }
            &:nth-child(2) {
                width: 35%;
            }
        }
    }
    tbody {
       tr {
            a.text-decoration-underline {
                color: #5a5a5a;
                font-weight: lighter;                
            }
            &:hover {
                a.text-decoration-underline {
                    color: #ef662f;
                    
                }
            }

            .transfer-action {
                display: flex;
                gap: 5px;
                button.dropdown-toggle.btn {
                    padding: 0;
                    svg {
                        margin-top: 0;
                    }
                }
                .dropright .dropdown-toggle::after {
                    display: none;
                }
                .dropdown-menu {
                    max-width: 750px;
                    overflow: auto;
                }

                .dropdown-item {
                    white-space: pre-wrap;
                }
            }
       }
    }
}