@mixin breakpoint($bp) {
    @if $bp==xl {
        @media only screen and (min-width: $screen-xl) {
            @content ;
        }
    }
    @else if $bp==lg {
        @media only screen and (min-width: $screen-lg) {
            @content ;
        }
    }
    @else if $bp==md {
        @media only screen and (min-width: $screen-md) {
            @content ;
        }
    }
    @else if $bp==sm {
        @media only screen and (min-width: $screen-sm) {
            @content ;
        }
    }
    @else if $bp==xs {
        @media only screen and (max-width: $screen-xs) {
            @content ;
        }
    }
}

@mixin placeholder($color) {
    &::-webkit-input-placeholder {
        // WebKit browsers
        color: #{$color};
    }
    &:-moz-placeholder {
        // Mozilla Firefox 4 to 18
        color: #{$color};
        opacity: 1;
    }
    &::-moz-placeholder {
        // Mozilla Firefox 19+
        color: #{$color};
        opacity: 1;
    }
    &:-ms-input-placeholder {
        // Internet Explorer 10+
        color: #{$color};
    }
}