// Variable overrides
$LatoHeavy: Lato-Heavy,
sans-serif;
$clr-main: #ff6f1b;
$clr-gray: #afb3bf;
$clr-89: #898989;
$clr-25: #252525;
// Extra small screen / phone
//** Deprecated `Extra small` as of 4.0
$screen-xs: 575px;
//** Deprecated `Small` as of 4.0
$screen-sm: 576px;
//** Deprecated `Medium` as of 4.0
$screen-md: 768px;
//** Deprecated `Large` as of 4.0
$screen-lg: 992px;
//** Deprecated `Extra large` as of 4.0
$screen-xl: 1200px;
$clr-bg-sidebar: #373737;
$clr-white: #fff;
$clr-black: #000;
$blue-1: #002e84;
$color-error: #d0082c;
$font-base-payment: Moderat,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;

$grey-1: #5a5a5a;
$grey-2: #e5e5e5;

$primary-1: #ef662f;
