* {
    text-rendering: optimizeLegibility;
}

html {
    -webkit-text-size-adjust: none;
    touch-action: manipulation;
}

body {
    background-color: #f6f9fa;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    color: #333333;

    .c-app {
        background-color: #f6f9fa;
        background-image: url(/images/bg-body.jpg);
        background-repeat: no-repeat;
        background-position: bottom left;
        font-size: 12px;
        font-family: Lato, sans-serif;
    }
}

@font-face {
    font-family: "Lato-Heavy";
    src: url("/fonts/LatoHeavy/Lato-Heavy.eot");
    src: url("/fonts/LatoHeavy/Lato-Heavy.eot?#iefix") format("embedded-opentype"), url("/fonts/LatoHeavy/Lato-Heavy.svg#Lato-Heavy") format("svg"), url("/fonts/LatoHeavy/Lato-Heavy.ttf") format("truetype"), url("/fonts/LatoHeavy/Lato-Heavy.woff") format("woff"), url("/fonts/LatoHeavy/Lato-Heavy.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("/fonts/LatoMedium/Lato-Medium.eot");
    src: url("/fonts/LatoMedium/Lato-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/LatoMedium/Lato-Medium.svg#Lato-Medium") format("svg"), url("/fonts/LatoMedium/Lato-Medium.ttf") format("truetype"), url("/fonts/LatoMedium/Lato-Medium.woff") format("woff"), url("/fonts/LatoMedium/Lato-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

* {
    &:focus {
        outline: none;
    }
}

.mg-t-30 {
    margin-top: 30px;
}

.mg-t-10 {
    margin-top: 10px;
}

.btn,
.request-form-wrap .step3 .btn {
    padding-top: 6px;
    padding-bottom: 5px;
    font-size: 12px;
    font-family: Lato-Heavy, sans-serif;
    line-height: 18px;
}

.btn-warning {
    background: rgba(240, 102, 47, 1);
    background: -moz-linear-gradient(left, rgba(240, 102, 47, 1) 0%, rgba(246, 148, 49, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(240, 102, 47, 1)), color-stop(100%, rgba(246, 148, 49, 1)));
    background: -webkit-linear-gradient(left, rgba(240, 102, 47, 1) 0%, rgba(246, 148, 49, 1) 100%);
    background: -o-linear-gradient(left, rgba(240, 102, 47, 1) 0%, rgba(246, 148, 49, 1) 100%);
    background: -ms-linear-gradient(left, rgba(240, 102, 47, 1) 0%, rgba(246, 148, 49, 1) 100%);
    background: linear-gradient(to right, rgba(240, 102, 47, 1) 0%, rgba(246, 148, 49, 1) 100%);
    border: none;
    color: #fff;
    line-height: 20px;
    border-radius: 20px;

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active {
        background: rgba(250, 80, 13, 1);
        background: -moz-linear-gradient(left, rgba(250, 80, 13, 1) 0%, rgba(224, 139, 54, 1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(250, 80, 13, 1)), color-stop(100%, rgba(224, 139, 54, 1)));
        background: -webkit-linear-gradient(left, rgba(250, 80, 13, 1) 0%, rgba(224, 139, 54, 1) 100%);
        background: -o-linear-gradient(left, rgba(250, 80, 13, 1) 0%, rgba(224, 139, 54, 1) 100%);
        background: -ms-linear-gradient(left, rgba(250, 80, 13, 1) 0%, rgba(224, 139, 54, 1) 100%);
        background: linear-gradient(to right, rgba(250, 80, 13, 1) 0%, rgba(224, 139, 54, 1) 100%);
        color: #fff;
    }

    &.disabled,
    &:disabled {
        border: 1px solid rgba(#808080, 0.8);
        background: rgba(#808080, 0.8);
        color: #fff;
        cursor: not-allowed;

        &:hover,
        &:focus,
        &:active {
            border: 1px solid rgba(#808080, 0.8);
            background: rgba(#808080, 0.8);
            color: #fff;
        }
    }
}

.btn-secondary {
    border-radius: 20px;
}

.btn-link-gray {
    color: #c8c8c8;
    border: none;
    padding: 0 6px;

    &:hover,
    &:focus {
        color: $clr-main;
        outline: none;
        border: none;
    }

    svg {
        width: 18px;
        height: 18px;
        position: relative;
        top: -3px;
    }

    div {
        display: inline-block;
    }

    .data-tip-class {
        font-size: 11px;
        font-weight: bold;
        font-family: Lato, sans-serif;
        color: #6f7782;
        border-radius: 5px;
        padding: 5px 10px;
        background: #dedede !important;
    }

    .data-tip-class::after {
        border-top-color: #dedede !important;
    }

    .active {
        display: none;
    }

    &:hover,
    &:focus {
        .normal {
            display: none;
        }

        .active {
            display: inline-block;
        }
    }
}

.btn-delete-user {
    padding: 0;
    background-color: transparent;
    border: none;
    color: #6f7782;
    border-radius: 0;
    line-height: 18px;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent !important;
        border: none !important;
        color: $clr-main !important;
        outline: none;
        box-shadow: none !important;
    }

    svg {
        margin-top: -0.15rem !important;
    }
}

.btn-comment-icon {
    &:hover {
        color: $clr-main !important;
    }
}

.full-name {
    position: relative;
    white-space: nowrap;
    line-height: 1.2;
    display: flex;
    align-items: center;
    .user-avatar-img {
        width: 31px;
        height: 31px;
        border-radius: 50%;
        background-color: #ccc;
    }

    .user-avatar {
        display: block;
        width: 31px;
        height: 31px;
        color: #fff;
        text-align: center;
        line-height: 31px;
        background-color: #fe8b90;
        font-family: $LatoHeavy;
        font-size: 14px;
        border-radius: 50%;

        &.white-stroke {
            border: 1px solid #ddd;
            color: #333;
        }
    }

    .txt {
        flex: 0 0 calc(100% - 31px);
        padding-left: 10px;
    }

    .avatar {
        position: relative;
        width: 100%;
        height: 100%;
        span {
            &.user-on-off {
                width: 9px;
                height: 9px;
                position: absolute;
                bottom: 0;
                right: 0;
                border-radius: 50%;
                border: 1px solid #fff;
                background-color: #b7b7b7;
                z-index: 10;
                &.on {
                    background-color: #1fbe31;
                }
            }
        }
    }
}

.list-users {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            &:not(:last-child) {
                margin-bottom: 4px;
            }

            .badge {
                font-weight: 900;
                font-size: 10px;
                padding: 3px 4px 5px;
                min-width: 87px;
            }
        }

        &.item-of-list {
            list-style: initial;
        }
    }

    span.action-type {
        padding: 3px 8px;
        color: #ffffff;
        font-size: 10px;
        border-radius: 5px;

        &.create-label {
            background-color: rgba(90, 181, 0, 0.693);
        }

        &.update-label {
            background-color: #65aecc;
        }

        &.export-label {
            background-color: #da5700;
        }

        &.delete-label {
            background-color: rgb(177, 0, 35);
        }

        &.dons-label {
            background-color: #f26621;
        }

        &.reset-label {
            background-color: rgb(181, 181, 181);
        }

        &.send-mail-label {
            background-color: #f2be38;
        }

        &.login-label {
            background-color: #f2be38;
        }

        &.change-password-label {
            background-color: #f26621;
        }
    }

    .svg-small {
        svg {
            width: 16px;
            height: 16px;
        }
    }

    table {
        margin-bottom: 0;
        border-bottom: 1px solid #eff0f3;

        td,
        th {
            border-color: #eff0f3;
            vertical-align: middle;

            &:first-child {
                padding-right: 0;
                padding-left: 15px;
            }
        }

        th {
            padding: 6px 10px;
            background-color: #f9f9fb;
            color: #808696;
            font-family: $LatoHeavy;
            font-size: 12px;
            border-width: 1px;
        }

        tbody {
            tr {
                background-color: #fff !important;

                &:nth-child(2n+2) {
                    background-color: #f9f9fb !important;
                }

                &:hover {
                    td {
                        &:first-child {
                            position: relative;

                            &:before {
                                content: "";
                                display: block;
                                width: 3px;
                                height: 100%;
                                left: 0;
                                top: 0;
                                background: rgb(242, 116, 66);
                                background: -moz-linear-gradient(0deg, rgba(242, 116, 66, 1) 0%, rgba(249, 174, 114, 1) 100%);
                                background: -webkit-linear-gradient(0deg, rgba(242, 116, 66, 1) 0%, rgba(249, 174, 114, 1) 100%);
                                background: linear-gradient(0deg, rgba(242, 116, 66, 1) 0%, rgba(249, 174, 114, 1) 100%);
                                //filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#f27442", endColorstr="#f9ae72", GradientType=1);
                                position: absolute;
                            }
                        }
                    }
                }

                td {
                    &.allowPointer {
                        cursor: pointer !important;

                        &:hover {
                            color: #fe8b90;
                        }
                    }

                    &.role-only {
                        span {
                            padding: 3px 8px;
                            font-weight: normal;
                            // min-width: 87px;
                        }
                    }
                    span {
                        &.level-high {
                            color: #ff5100;
                        }
                        &.level-medium {
                            color: #dbc501;
                        }
                        &.level-low {
                            color: #929292;
                        }
                    }
                }
            }
        }

        .td-checkbox {
            width: 28px;
        }

        .action {
            white-space: nowrap;

            a {
                color: #c8c8c8;

                &:hover {
                    color: #fd6a14;
                }

                &:disabled,
                &.disabled {
                    cursor: not-allowed;
                    pointer-events: none;
                }
            }
        }

        .custom-control-inline {
            margin-right: 0;
        }

        .custom-control {
            padding-left: 16px;
            margin-top: 4px;

            .custom-control-label {

                &:before,
                &:after {
                    left: -16px !important;
                }
            }
        }
    }

    .type {
        .badge {
            min-width: 87px;
            line-height: 19px;
            font-size: 10px;
            padding: 0 5px;
            font-weight: 900;
        }
    }

    .ambassador {
        color: #f28b05;

        .badge {
            color: #f28b05;
            background-color: #f4d5b5;
        }
    }

    .director {
        color: #9f8cdc;

        .badge {
            color: #9f8cdc;
            background-color: #efecfc;
        }
    }

    .vice-director {
        color: #cbc042;

        .badge {
            color: #cbc042;
            background-color: #fbf6c4;
        }
    }

    .super-admin {
        color: #fe8b90;

        .badge {
            color: #fe8b90;
            background-color: #f7c9cb;
        }
    }

    .controller {
        color: #65aecc;

        .badge {
            color: #65aecc;
            background-color: #cfeaf5;
        }
    }

    .advisor {
        color: #43aa8c;

        .badge {
            color: #43aa8c;
            background-color: #d9f3e8;
        }
    }

    .advisor {
        color: #60aca7;

        .badge {
            color: #60aca7;
            background-color: #afddda;
        }
    }

    .type-dons {
        color: #fd6a14;
    }
}

.filters-user {
    margin-right: 24px;

    .form-check-input.hide-inactive {
        margin: -8px;
    }

    .select-record {
        height: calc(1.2em + 0.75rem + 2px);
        font-size: 0.76563rem;
        padding: 0.3rem 0.75rem;
    }

    .showing-record {
        margin-right: 14px;
    }

    &.d-xl-flex {
        margin-right: 0;

        @include breakpoint(xl) {
            margin-right: 10px;
        }

        .showing-record {
            margin-right: 0;
            #records {
                width: 43px;
                padding: 0 0 2px;
                text-align: center;
            }
            @include breakpoint(sm) {
                margin-right: 10px;
            }
        }
    }

    &.d-sm-flex {
        margin-right: 0;

        @include breakpoint(sm) {
            margin-right: 24px;
        }

        .showing-record {
            margin-right: 0;

            @include breakpoint(sm) {
                margin-right: 14px;
            }
        }
    }

    .row {
        margin-left: -7px;
        margin-right: -7px;

        &.mg-l-7 {
            margin-left: 7px;
        }

        [class*="col-"],
        .col {
            padding-right: 7px;
            padding-left: 7px;
        }
    }

    .form-group {
        margin-bottom: 14px;

        &.filter-department {
            margin-left: 14px;
            position: relative;

            @include breakpoint(xs) {
                margin-right: 0;
            }
        }
    }

    .form-control {
        border-color: #f0f2f2;
        background-color: #f0f2f2;

        &:disabled {
            background-color: #d8dbe0;
        }
    }

    &.reference-list {
        .form-group {
            &.filter-department {
                margin-right: 14px;
                margin-left: 0;

                @include breakpoint(xs) {
                    margin-right: 0;
                }
            }
        }
    }

    .btn-wipe-out {
        margin-bottom: 14px;
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 10px;
    }
}

.label-filter {
    color: #707070;

    @include breakpoint(sm) {
        margin-right: 12px;
        margin-bottom: 14px;
    }
}

.title-card-header {
    font-family: $LatoHeavy;
    color: #252525;
    font-size: 14px;
    margin: 0 0 14px;
    text-transform: uppercase;
}

.card-header {
    z-index: 15;
    .log-types {
        .select-log-types {
            border-color: #f0f2f2;
            background-color: #f0f2f2;
            height: calc(1.2em + 0.75rem + 2px);
            font-size: 0.76563rem;
        }
    }
}
.z-index-20 {
    z-index: 20 !important;
}
.z-index-19 {
    z-index: 19 !important;
}
.z-index-18 {
    z-index: 18 !important;
}
.search-user {
    min-width: 200px;
    margin-bottom: 14px;
    margin-right: 10px;
    .form-control {
        height: 27px;
        font-size: 0.76563rem;
        padding: 0 8px;
        border-radius: 14px;
        padding-left: 14px;
        padding-right: 14px;
    }
}

.content-user-bottom {
    padding: 0 25px 15px;
    margin-top: 15px;

    .mt-2 {
        margin-top: 0 !important;
    }

    .pagination {
        margin-bottom: 0;
    }

    .btn {
        padding: 0;
    }

    .btns-group-bottom {
        margin-left: auto;
    }
}

.btn-add-user {
    font-size: 12px;
    color: #6f7782;
    font-family: $LatoHeavy;
    padding: 0;

    .c-icon {
        color: #6f7782;
        font-size: 12px !important;
        width: auto !important;
        height: 0.875rem !important;
    }

    &:focus {
        outline: none;
        border: none;
    }
}

.custom-switch-sm {
    width: 36px;
    height: 18px;

    .c-switch-slider {
        border-color: #f0737a;
        background-color: #f0737a;

        &:before {
            top: 1px;
            left: 2px;
            width: 14px;
            height: 14px;
            border: none;
        }

        &:after {
            display: none;
        }
    }

    &.c-switch-success .c-switch-input:checked+.c-switch-slider {
        background-color: #4ebf9e;
        border-color: #4ebf9e;

        &:before {
            transform: translateX(17px);
        }
    }

    &.c-switch-secondary .c-switch-input:checked+.c-switch-slider {
        &:before {
            transform: translateX(17px);
        }
    }
}

.custom-loading {
    inset: 39px 0px 1px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);

    .loading-middle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        span {
            span {
                margin: 5px;
            }
        }
    }
}

.c-avatar {
    width: 33px;
    height: 33px;
    padding: 0;
    text-align: center;
    line-height: 33px;
    background-color: #eb1d5d;
    border-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 16px;
}

.checkbox-inside-form {
    margin-bottom: 0;
}

.btn-light {
    height: 30px;
    vertical-align: top;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 3px 5px;
    margin-right: 10px;
    position: relative;
    bottom: 5px;
}

.btn.disabled,
.btn:disabled {
    cursor: not-allowed;
}

.c-switch-input:disabled~.c-switch-slider {
    opacity: .5 !important;
    background-color: #ced2d8 !important;
    border-color: #ced2d8 !important;
}

//Datepicker
.react-datepicker-wrapper {
    width: 100%;

    .filter-date {
        padding: 4px 7px;
        border: 1px solid #ddd;
        border-radius: 4px;
        width: 100%;
    }
}

label.icon.iconCalendar.calendar {
    position: absolute;
    right: 20px;
    top: -4px;
}

@media (min-width: 768px) {
    .label-display-by {
        margin-left: 40px;
        vertical-align: top;
        position: relative;
        bottom: 6px;
        margin-right: 12px;
        color: #707070;

        .lbl-hideInactive::before,
        .lbl-hideInactive::after {
            top: 1px;
        }
    }

    .label-display-by:before {
        content: "";
        height: 28px;
        width: 1px;
        background-color: #e2e2e2;
        position: absolute;
        top: -3px;
        left: -40px;
    }
}

.invalid-feedback {
    display: block;
}

.card-agent-form {
    h4 {
        text-transform: uppercase;
        font-size: 1.09375rem;
    }
    .calendar-switch {
        position: absolute;
        top: -40px;
        right: 30px;
        z-index: 10;
        button {
            text-transform: uppercase;
            font-size: 11px;
            color: #959595;
            &.btn-active {
                color: #000;
            }
        }
    }
}

body .react-trello-board {
    background-color: #fff;
}

.cursor-pointer {
    cursor: pointer;
}

.btn:focus,
.btn.focus {
    box-shadow: none;
}

.loading-form {
    height: 40px;
    position: relative;

    .custom-loading {
        inset: 1px 0 1px;
    }
}

.form-tiny {
    max-width: 565px;
    margin: 0 auto;

    .block-add-user {
        padding-bottom: 14px !important;
    }

    .btn-form-add-user {
        padding-top: 25px;
        text-align: right;
    }
}

.modal-body-padding {
    padding: 20px 80px;

    label.icon {
        &.iconCalendar {
            &.calendar {
                top: 26px;
            }
        }
    }

    .input-picker-color {
        span {
            float: left;

            span {
                border: 1px solid #bebebe;
            }
        }
    }
}

.block-input-inline {
    margin: 20px;

    .input-edit-inside {
        background: linear-gradient(to right, #f26621, #f7941e);
        padding: 10px 20px;
        display: block;
        width: 100%;
        border-radius: 8px;

        label {
            font-size: 13px;
            text-align: left;
            color: #fff;
            font-weight: 600;
        }

        input {
            background: linear-gradient(to right, #f26621, #f7941e);
            border: 0;
            padding: 15px;
            font-size: 40px;
            width: auto;
            max-width: 100%;
            margin: auto;
            color: #FFFFFF;
            text-align: center;
            border-radius: 8px;

            &:hover,
            &:focus {
                background: #fff;
                cursor: pointer;
                color: #000;
            }


            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &[type=number] {
                -moz-appearance: textfield;
            }
        }

        &.disabled {
            input {
                background: linear-gradient(to right, #f26621, #f7941e);
                color: #FFFFFF;
                cursor: auto;
            }
        }
    }
}

.block-add-user {
    label.icon {
        &.iconCalendar {
            &.calendar {
                top: 26px
            }
        }
    }

    .form-control {
        height: 32px;
        font-size: 12px;
        font-weight: 700;
        border-color: #c4c7d0;
    }

    .add-generate-pass {
        position: relative;
        .btn-generate-password {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
            padding-top: 6px;
            background-color: transparent;
            border: none;
            color: #fd7e24;
            font-family: Lato, sans-serif;
            font-size: 12px;
        }
    }
    &.no-label {
        .custom-upload-file {
            label {
                display: inline-block;
                margin-bottom: 20px;
                margin-top: 6px;
                span {
                    background-color: gray;
                    color: #ffffff;
                    border-radius: 20px;
                    padding: 5px 10px;
                    font-size: 12px;
                }
            }
            .btn-warning {
                float: right;
                margin-top: 0;
                padding: 3px 10px;
            }
        }
        label {
            display: none;
        }
    }
    .custom-upload-file {
        .logo-upload {
            position: relative;
            margin-bottom: 10px;
            img {
                width: 150px!important;
            }
            .icon-trash {
                position: absolute;
                top: 0;
                left: 170px;
                color: #FF0000;
                cursor: pointer;
            }
        }
    }
}

// CUSTOM LAYOUT ADMIN
.c-app-custom {
    display: block;
    width: 100%;
}

.message-top {
    display: flex;
    width: 100%;
    height: auto;
    line-height: 30px;
    color: #fff;
    text-align: center;
    float: left;
    .messge-c-sidebar {
        width: 256px;
        @media (max-width: 768px) { 
            width: auto;
        }
    }

    .message-content {
        padding: 10px 40px;
        line-height: 1.3rem;
        text-align: center;
        flex: 1;
    }

    .notification-off {
        position: absolute;
        font-weight: bold;
        font-size: 10px;
        cursor: pointer;
        top: 0;
        right: 0;
        padding: 5px 15px;
        color: #565656;
    }
}

.full-container {
    float: left;
    display: flex;
    width: 100%;
    flex-direction: row;
    min-height: 100vh;
    position: relative;

    .c-sidebar {
        &.c-sidebar-fixed {
            &.sidebar-notification {
                position: absolute;
            }
        }
    }
}

.form-upload-file {
    .custom-upload-file {
        &:after {
            content: "";
            display: table;
            clear: both;
        }
        label {
            margin-top: 10px;
            font-size: 12px;
            span {
                background-color: gray;
                color: #ffffff;
                border-radius: 20px;
                padding: 5px 10px;
                font-size: 12px;
            }
        }
        .logo-upload {
            img {
                max-width: 60px;
                height: auto;
                background-color: #ccc;
            }
        }
        .btn-warning {
            width: 100%;
            margin-top: 10px;
            @media (min-width: 576px) {
                float: right;
                width: 30%;
                margin-top: 0;
            }
            &.disabled {
                border-color: rgba(128, 128, 128, 0.8);
                background-color: rgba(128, 128, 128, 0.8);
                color: #fff;
            }
        }
    }
    &.custom-for-campagne {
        .btn-warning {
            @media (min-width: 576px) {
                float: right;
                width: 50%;
                margin-bottom: 15px;
            }
        }
    }
}

.react-select {
    z-index: 16;
}

.card-body-add-user {
    &.my-hub-box {
        padding: 60px 100px;
        h4.frm-profile-title {
            font-size: 14px;
            font-weight: 600;
            color: #000;
        }
    }
}

.profile-step-2 {
    min-height: 350px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
    .icon {
        text-align: center;
        margin-top: 100px;
        img {
            width: 120px;
            border-radius: 50%;
            margin-bottom: 20px;
        }
    }
    .name-of-profile {
        font-family: Lato, sans-serif;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0;
        color: #000;
    }
    .hr-step-2 {
        border-color: #000;
        border-width: 2px;
    }
    .field-of-profile {
        text-align: center;
        border-bottom: none;
        padding-bottom: 10px;
        margin-bottom: 5px;
        &.has-border {
            border-bottom: 1px solid #eee;
            margin-bottom: 0;
            padding: 0;
            line-height: 35px;
        }
        span {
            font-weight: bold;
            font-size: 13px;
            margin-left: 10px;
            .text-link {
                color: #3c4b64;
            }
        }
    }
    ul.profile-info-list {
        list-style: none;
        padding-left: 0;
        li {
            border-bottom: 1px solid #eee;
            line-height: 35px;
            p {
                margin-bottom: 0;
                padding-bottom: 0;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .text-center {
        button {
            border-radius: 2rem;
            padding-left: 20px;
            padding-right: 20px;
            background: #fd752d;
            padding: 5px 35px;
        }
    }
}

.more-field {
    .form-group {
        .react-datepicker__close-icon {
            right: 0;
        }
    }
}

.status-campagne {
    padding-left: 5px;
    white-space: nowrap;
    &::before {
        content: "";
        display: inline-block;
        margin-right: 7px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        vertical-align: 2px;
    }
    &.preperation {
        &::before {
            background-color: #ef6e7e;
        }
    }
    &.in-progress {
        &::before {
            background-color: #84c99e;
        }
    }
    &.completed {
        &::before {
            background-color: #2a9d8f;
        }
    }
}
.list-table-privileges {
    th:nth-child(2) {
        width:25%;
    }
}
.mr-10 {
    margin-right: 10px;
}
.btn-add-user.is-icon:focus svg, 
.btn-add-user.is-icon:focus polygon, 
.btn-add-user.is-icon:active svg, 
.btn-add-user.is-icon:active polygon
{
    color: #fff;
}
.list-table-campane {
    .campagne-td-avatar {
        img, span {
            margin: 0 13px;
        }
    }
    // tbody {
    //     td {
    //         padding: 4px 12px;
    //     }
    // }
}
.body-left {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s linear;
}
.d-none.body-left {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s linear 300ms, opacity 300ms;
}

.col-transition {
    transition: all 0.3s linear;
    &.col-normal {
        @media (min-width: 768px) {
            // flex: unset;
            // max-width: unset;
            width: 66.66667%;
        }
    }
    &.col-normal-2 {
        @media (min-width: 768px) {
            width: 75%;
        }
    }
    &.col-full {
        @media (min-width: 768px) {
            // flex: unset;
            // max-width: unset;
            width: 100%;
        }
    }
}
.col-hidden {
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transform: translateX(-100%);
    position: absolute;
}

.segment-script-page {
    .billing-block {
        .collapse-header {
            border-color: #ebebeb;
            padding: 1rem 1.25rem;
            h4 {
                &.collapse-title {
                    font-size: 12px;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        .no-label {
            label {
                display: none;
            }
        }
    }   
}

.card-body-add-user {
    .react-datepicker-popper {
        z-index: 20;
    }
}

.loading-segment-fields {
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 10;
}

.segments-tab-pane {
    .btn-add-round {
        margin-top: 20px;
    }

    .btn-add-row {
        margin: 5px;
        svg {
            color:#b10023 !important;
            fill: none!important;
        }
        &:hover {
            svg {
                color:#b10023 !important;
            }
        }
    }

    table.segment-data-result {
        width: 100%;   
        text-align: center;
        overflow-y: auto;
        margin-bottom: 0;
    
        tr {
            th {
                background-color: #4e4e4e;
                border-color: #a8a8a8;
                padding: 10px 0;
                color: #fff;
            }
        }
        td, th {
            border: 1px solid #a8a8a8;
            padding: 5px;
            color: #000;
            font-weight: 600;
            &:last-child {
                background-color: transparent;
                border: none;
                align-items: center;
                justify-content: center;
                display: flex;
                .btn-delete-row {
                    width: 25px;
                    height: 25px;
                    background-color: transparent;
                    margin-bottom: 0;
                    &:active, &:focus {
                        svg {
                            color: #6f7782;
                        }
                    }
                    &:hover {
                        svg {
                            color: #dc0303;
                        }
                    }
                }
            }
        }
        input {
            &.input-inline {
                border-radius: 3px;
                background-color: transparent;
                border: 0;
                padding: 8px;
                text-align: center;
                color:#ff6a13;
                font-weight: 600;
                width: 100%;
                &:hover {
                    background-color: #eee;
                    cursor: pointer;
                }
            }
        }
    
        textarea {
            &.input-inline {
                border-radius: 3px;
                background-color: transparent;
                border: 0;
                padding: 8px;
                text-align: left;
                color:#ff6a13;
                font-weight: 600;
                width: 100%;
                min-height: 8em;
                overflow: auto;
                &:hover {
                    background-color: #eee;
                    cursor: pointer;
                }
            }
        }

        p.input-inline-error {
            font-size: 80%;
            color: #e55353;
        }
    }
}

.box-notice {
    p {
        margin-bottom: 0;
    }
    ul {
        padding: 10px;
        margin-bottom: 0;
    }
}

.notice-access {
    font-weight: 500;
    font-size: 12px;
    color: #815c15;
    line-height: 1.25;
    padding: 10px;
    background-color: #feefd0;
    margin-bottom: 10px;
    border-radius: 5px;
}

p.notice-modal-error {
    font-size: 12px;
    color: red;
    margin-bottom: 0;
}

.td-bg-opacity {
    .react-datepicker__input-container {
        width: auto;
    }
}