.modal-event-custom {
    .modal-body-event {
        label {
            &.icon {
                &.iconCalendar {
                    &.calendar {
                        top: 25px
                    }
                }
            }
        }

        .form-control {
            border-color: #f2f2f2;
            background-color: #f2f2f2;
            border-radius: 3px;
            color: #898989;
            font-weight: 700;
            font-size: 10px;
            height: 33px;
            &:disabled {
                background-color: #d6d6d6;
            }
        }
        .custom-control-label {
            padding-top: 2px;
        }
        textarea.form-control {
            height: 78px;
        }
        hr {
            border-color: #e5e5e5;
            margin-top: 0;
            margin-bottom: 14px;
        }
        .form-group {
            margin-bottom: 14px;
        }
        .no-label {
            label {
                opacity: 0;
                display: none;
            }
        }
        .time-pause {
            height: 33px;
            border-radius: 3px;
            border: none;
            background-color: #f2f2f2;
            width: 157px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 14px;
            padding-left: 7px;
            .form-group {
                margin-bottom: 0;
            }
            span {
                color: #898989;
                font-weight: 700;
                font-size: 10px;
            }
            &.disabled-field {
                background-color: #d6d6d6;
                .form-control {
                    background-color: #d6d6d6;
                    border-color: #d6d6d6;
                }
            }
        }
        .time-field-custom {
            text-align: right;
            display: block;
            input {
                display: block;
                text-align: center;
                float: right;
                text-align: right;
                width: 70px;
                padding: 4px 7px;
                border: 1px solid #f2f2f2;
                border-radius: 3px;
                height: 33px;
                font-weight: 400;
                line-height: 1.5;
                background-clip: padding-box;
                color: #252525;
                background-color: #f2f2f2;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                margin: 0;
                font-family: $LatoHeavy;
                font-size: 14px;
            }
        }
    }

    .modal-event-footer {
        padding: 20px 45px 68px;
        .btn {
            min-width: 48% !important;
            &:nth-child(2n+2) {
                margin-right: 0;
            }
        }
    }
}

@media only screen and (min-width: 576px) {
    .modal-event-custom {
        .modal-sm {
            max-width: 450px;
        }
    }
    
}

.fc-block {
    width: 100%;
    display: block;
    position: relative;
    padding: 0 22px;
    .fc-loading {
        position: absolute;
        opacity: .7;
        background-color: #fff;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }

    .fc-timeGridWeek-view {
        p {
            &.fc-event-description {
                display: none;
            }
        }
    }

    .fc-timeGridDay-view {
        p {
            &.fc-event-description {
                display: block;
            }
        }
    }

    .fc-col-header-cell-cushion {
        text-transform: capitalize;
    }

    .full-calendar-custom {

        .fc-daygrid-body,
        .fc-col-header,
        .fc-scrollgrid-sync-table,
        .fc-timegrid-body {
            width: 100% !important;

            table {
                width: 100% !important;
                tr {
                    background-color: rgba(255, 255, 255, 0.622);
                    &:nth-child(2n+1) {
                        background-color: rgba(249, 249, 251, 0.622);
                        z-index: 1;
                    }
                    td {
                        border-color: #eeecec;
                        a {
                            &.fc-daygrid-day-number {
                                font-family: Lato, sans-serif;
                                font-size: .9em;
                            }
                        }
                        &.fc-day-disabled {
                            background-color: #f9f9fb!important;
                        }
                        &.fc-day {
                            background-color: #fff;
                            &.fc-day-today {
                                background-color: #feead8;
                            }
                        }
                    }
                }
            }
        }

        td {
            .fc-daygrid-event {
                &.fc-event-end {
                    background-color: #ff6918;
                    color: #fff;
                    border-color: transparent;

                    &.fc-daygrid-dot-event {
                        padding: 0;
                    }

                    &.event-confirm {
                        background-color: #2eb85c;
                        border-color: #2eb85c;
                        .fc-event-time {
                            background-color: #228d46;
                        }
                    }

                    &.event-vacation {
                        background-color: #df0000;
                        border-color: #df0000;
                        .fc-event-time {
                            background-color: #be0202;
                        }
                    }

                    &.event-vacation-confirm {
                        background-color: #ecf403;
                        border-color: #ecf403;
                        .fc-event-time {
                            background-color: #bdc404;
                        }
                    }

                    b {
                        color: #fff;
                        padding: 0 5px;
                    }
                }
            }

            .fc-timegrid-event-harness-inset {
                .fc-timegrid-event {
                    background-color: #f26621;
                    border-color: #f26621;

                    &.event-confirm {
                        background-color: #2eb85c;
                        border-color: #2eb85c;
                    }

                    &.event-vacation {
                        background-color: #df0000;
                        border-color: #df0000;
                    }

                    &.event-vacation-confirm {
                        background-color: #ecf403;
                        border-color: #ecf403;
                    }

                    .fc-event-main {
                        b {
                            padding: 0 5px;
                        }
                    }
                }
            }

            a {

                &.fc-daygrid-event,
                &.fc-timegrid-event {
                    cursor: pointer;
                }
            }
        }

        .fc-event-main {
            overflow: hidden;
        }

        .fc-timegrid-col-frame {
            .fc-event-box {
                width: 100%;

                p {
                    font-size: 11px;
                    padding: 0 5px;

                    b {
                        font-style: normal;
                        padding-left: 0 !important;
                        text-transform: initial;
                        color: #fff;
                    }

                    &.fc-event-time {
                        font-weight: 600;
                        font-size: 12px;
                        &:after {
                            content: '';
                        }
                    }

                    &.fc-event-title {
                        font-size: 12px;
                        margin-bottom: 10px;
                    }

                    &.fc-event-pause {
                        margin-bottom: 0;
                    }

                    &.fc-event-campagne {
                        margin-bottom: 0;
                        text-transform: uppercase;
                    }

                    &.fc-event-segmentscript {
                        margin-bottom: 0;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .fc-daygrid-day-frame {
            .fc-daygrid-day-bg {
                background-color: #f26621;
                display: none;
            }

            .fc-event-box {
                width: 100%;

                p {
                    margin-bottom: 0;

                    &.fc-event-time {
                        font-size: 10px;
                        font-weight: 700;
                        float: left;
                        width: 42px;
                        text-align: center;
                        padding: 2px 0;
                        margin: 0;
                        background-color: #e76437;
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                    }

                    &.fc-event-title {
                        font-size: 10px;
                        padding: 2px 0;
                        text-align: center;

                        b {
                            display: none;
                        }
                    }

                    &.fc-event-description,
                    &.fc-event-campagne,
                    &.fc-event-pause,
                    &.fc-event-segmentscript {
                        display: none;
                    }
                }
            }

        }

        .fc-scrollgrid-liquid {
            thead {
                position: relative;
                z-index: 10;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
                -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
                tr {
                    th {
                        &:first-child {
                            border-right: none!important;
                        }
                        &:nth-child(2) {
                            border-left: none!important;
                        }
                        border-bottom: none!important;
                    }
                }
            }
            .fc-timegrid-cols {
                .fc-day-today {
                    background-color: rgba(255, 94, 0, 0.266);
                    z-index: 10;
                }
            }
            .fc-col-header  {
                a {
                    font-family: Lato, sans-serif;
                    font-weight: normal;
                    color: #000;
                }
            }
        }

    }

    // ITEM WHEN DRAGGING
    .fc-event-dragging {
        &.fc-daygrid-event {
            .fc-event-box {
                width: 100%;
                background-color: #f26621;
                color: #fff;
                padding: 2px 0;
                border-radius: 3px;

                p {
                    margin-bottom: 0;

                    &.fc-event-time {
                        font-size: 10px;
                        font-weight: 700;
                        float: left;
                        padding: 0 5px;
                        margin: 0 3px;
                        background-color: #2b3e4e;
                        border-radius: 3px;
                    }

                    &.fc-event-title {
                        font-size: 10px;

                        b {
                            display: none;
                        }
                    }

                    &.fc-event-description,
                    &.fc-event-campagne,
                    &.fc-event-pause,
                    &.fc-event-segmentscript {
                        display: none;
                    }
                }
            }
        }

        &.fc-unselectable {
            background-color: #727272;
            border-color: #727272;

            .fc-event-box {
                width: 100%;

                p {
                    font-size: 11px;
                    padding: 0 5px;

                    b {
                        font-style: normal;
                        padding-left: 0 !important;
                        text-transform: initial;
                    }

                    &.fc-event-time {
                        font-weight: 600;
                        font-size: 12px;
                    }

                    &.fc-event-campagne {
                        margin-bottom: 0;
                        text-transform: uppercase;
                    }

                    &.fc-event-segmentscript {
                        margin-bottom: 0;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .fc {
        .fc-header-toolbar {
            &.fc-toolbar {
                display: flex;
                width: 340px;
                margin: auto;
                align-items: center;
                margin-bottom: 10px;
                .fc-toolbar-chunk {
                    float: left;
                    margin: 0;
                    button {
                        background-color: transparent;
                        color: #5a5a5a;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        padding: 0.4em 0;
                    }
                    h2 {
                        font-family: Lato-Heavy, sans-serif;
                        text-transform: uppercase;
                        font-size: 1rem;
                        color: #5a5a5a;
                    }
                }
            }
        }
    }

}
.header-filter-fc {
    padding: 16px 22px 0;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 18px;
}
.fc-report-filter {
    .row {
        margin-left: -5px;
        margin-right: -5px;
        [class*="col-"] {
            padding-left: 5px;
            padding-right: 5px;
        }
        .col-md-3 {
            max-width: 146px;
            min-width: 146px;
        }
    }
    .form-control {
        border-color: #f2f2f2;
        background-color: #f2f2f2;
        height: 25px;
        border-radius: 3px;
        font-weight: 600;
        font-size: 10px;
        color: #5a5a5a;
        @include placeholder(#5a5a5a);
        padding: 2px 8px;
        // &.select-filter {
        //     min-height: 25px;
        //     font-size: 12px;
        //     font-family: Lato, sans-serif;
        //     font-weight: normal;
        // }
    }
    .react-select {
        border-color: #f2f2f2;
        background-color: #f2f2f2;
        height: 25px;
        border-radius: 3px;
        font-weight: 600;
        font-size: 10px;
        color: #5a5a5a;
        @include placeholder(#5a5a5a);
        padding: 2px 8px;
        span+div {
            border: none;
            padding: 0;
            min-height: unset;
            background-color: transparent;
            border: none;
            line-height: normal;
            color: #5a5a5a;
            box-shadow: none;
            div {
                color: #5a5a5a;
                padding: 0;
            }
            span {
                display: none;
            }
        }
        svg {
            width: 16px;
        }
    }
}

.block-total-hours {
    margin-bottom: 10px;
    display: flex;
    h4 {
        text-transform: inherit;
        font-weight: 500;
        color: #5a5a5a;
        &:first-child {
            font-family: Lato-Heavy, sans-serif;
        }
        &:not(:first-child) {
            margin-left: 14px;
        }
    }
    h4.fc-total-hours {
        font-size: 14px;

        span {
            color: #fc6a28;
        }
    }
}

.tab-content-fc {
    &.bg-white {
        border-radius: 0 0 0.25rem 0.25rem;
    }
    label {
        &.icon {
            &.iconCalendar {
                &.calendar {
                    right: 20px;
                }
            }
        }
    }
    .export-button {
        position: absolute;
        right: 20px;
        top: 10px;
        .btn-export {
            background-color: transparent;
            &:hover {
                background-color: #f4f4f4;
            }
            &:focus {
                background-color: #c7c7c7;
            }
        }
   }
}

// MODAL EXPORT
.modal-export-timesheet {
    .col-sm-auto {
        width: 100%;
        .form-group {
            width: 100%;
        }
    }
    .modal-footer-cs {
        padding-bottom: 50px;
        .btn {
            min-width: 100px;
        }
    }
    label {
        &.icon {
            &.iconCalendar {
                &.calendar {
                    display: none;
                }
            }
        }
    }
    .modal-footer {
        .btn {
            margin-left: 10px;
            min-width: 80px;
            &.btn-outline-warning {
                border-radius: 20px;
            }
        }
    }
    .react-datepicker-popper {
        z-index: 20;
    }
}

.z-index-10 {
    z-index: 10;
}

.listview-timesheet {
    overflow: auto;
    height: calc(100vh - 255px);//750px;
    z-index: 10;

    table {
        border-bottom: none;
        width: 100%;
        z-index: 1;
        border-collapse: collapse;

        thead {
            position: relative;
            th {
                position: sticky;
                top: 0;
                z-index: 100;
                border: 1px solid #ddd;
                background-color: #eee;
                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    border-top: 1px solid #ddd;
                    display: block;
                    top : -1px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-bottom: 1px solid #ddd;
                    display: block;
                    top : 1px;
                }

                &.th-checkbox {
                    padding-right: 15px;
                    .checkbox-all-header {
                        z-index: 10;
                    }
                }

                &.action-head {
                    min-width: 75px;
                }
            }

            tr {
                &:nth-child(1) {
                    th {
                        position: sticky;
                        top: 0px;
                        z-index: 100;
                        border-left: 1px solid #ddd;
                    }
                }
                &:nth-child(2) {
                    th {
                        position: sticky;
                        top: 31px;
                        z-index: 100;
                    }
                }
            }
        }

        tbody {
            td {
                border: 1px solid;
                border-color: #d4d4d4;
                padding: 0.75rem;
                
                &:first-child {
                    padding-right: 15px;
                }

                &.no-break-line {
                    padding: 0;
                    p {
                        white-space: nowrap;
                        padding: .3rem .75rem;
                        border-bottom: 1px solid #f1f1f1;
                        border-right: 1px solid #f1f1f1;
                        margin-bottom: 0;
                        height: 30px;
                        &:last-child {
                            border-color: transparent;
                            border-right: 1px solid #f1f1f1;
                        }
                        &.border-left {
                            border-left: 1px solid #f1f1f1!important;
                        }
                    }
                    .row-item {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #f1f1f1;
                        border-left: 1px solid #f1f1f1;
                        p {
                            white-space: nowrap;
                            padding: 0 .75rem;
                            border: none;
                            height: auto;
                        }
                        &:last-child {
                            border-color: transparent;
                            border-left: 1px solid #f1f1f1;
                        }
                    }
                }
                &.loading-listview-timesheet {
                    position: relative;
                    height: calc(100vh - 303px);
                    border: none;
                }
                &.no-border {
                    border: none;
                    border-color: transparent;
                }
                &.text-center {
                    text-align: center;
                }

                p.line-inside {
                    margin-bottom: 0;
                    border-bottom: 1px solid #f1f1f1;
                    padding: .25rem .75rem;
                    height: 27px;
                    white-space: nowrap;

                    &:last-child {
                        border: none;
                    }
                }

                &.td-checkbox {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }

        tfoot {
            &.footer-total {
                position: relative;
                tr {
                    position: sticky;
                    bottom: 0px;
                    z-index: 100;
                    background-color: #f9f9fb;
                    height: 30px;

                    td {
                        border-left: 1px solid #ddd;
                        background-color: #eee;
                        font-family: Lato-Heavy, sans-serif;
                        color: #fc6a28;
                        &:before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            right: 0;
                            border-top: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                            display: block;
                            bottom : 0px;
                        }

                        &.action-footer {
                            button {
                                position: relative;
                                z-index: 200;
                                font-size: 12px;
                                
                                svg {
                                    width: 14px;
                                    height: 14px;
                                    top: 0;
                                    left: -3px;
                                }
                            }
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        left: -1px;
                        width: 100%;
                        height: 100%;
                        border-left: 1px solid #ddd;
                        border-bottom: 1px solid #ddd;
                        display: block;
                        top : 1px;
                    }
                }
            }
        }
    }

    .toggle-list {
        max-height: 400px;
        overflow: auto;
    }
}

.header-list-assignation {
    border: none;
    padding: 15px 0!important;
}

.filters-block {
    .react-datepicker-popper {
        z-index: 20!important;
    }
    .filter-date {
        background-position: 80% center;
    }
}

// modal add
.modal-event-custom {
    .modal-header {
        padding: 56px 45px 22px;
        border: none;
        .modal-title {
            font-size: 28px;
            font-family: $LatoHeavy;
            color: #252525;
        }
    }
}
.modal-body-event {
    padding: 0 45px;
}

// list view result
.listview-result {
    height: unset;
    &>table {
        &>tbody {
            &>tr {
                &>td {
                    &:first-child {
                        border-right: 2px solid #fff;
                        background-color: transparent !important;
                    }
                }
                &.in-progress {
                    &>td {
                        &:first-child, &:nth-child(2) {
                            background: #ebefed !important;
                        }
                    }
                }
                &.preparation {
                    &>td {
                        &:first-child, &:nth-child(2) {
                            background: #f3e2e6 !important;
                        }
                    }
                }
                &.completed {
                    &>td {
                        &:first-child, &:nth-child(2) {
                            background: #cce2e0 !important;
                        }
                    }
                }
            }
        }
    }
    table {
        width: 100%;
        font-size: 11px;
        table-layout: fixed;
        th {
            border-top: 1px solid #e5e5e5;
            padding: 14px 12px;
            font-weight: 700;
            font-size: 11px;
        }
        td {
            padding: 2px 12px;
            // vertical-align: top;
            vertical-align: middle;
            height: 56px;
            .progress {
                background-color: #fff !important;
            }
        }

        tbody tr {
            &.preparation {
                // background: linear-gradient(90deg, rgba(243,221,228,1) 0%, rgba(254,244,247,1) 100%);
                background: #feeef3 !important;
                td {
                    background-color: transparent;
                }
                .td-change-color, .number-progress, .dropdown-header, .pointer {
                    color: #ef6e7e;
                }
            }
            &.completed {
                // background: linear-gradient(90deg, #e9eeeb 0%, #f8f9fa 100%);
                background: #e4f3f1 !important;
                td {
                    background-color: transparent;
                }
                .td-change-color, .number-progress, .dropdown-header, .pointer {
                    color: #84c99e;
                }
            }
            &.in-progress {
                // background: linear-gradient(90deg, #eff4ef 0%, #fefffe 100%);
                background: #f6f9f8 !important;
                td {
                    background-color: transparent;
                }
                .td-change-color, .number-progress, .dropdown-header, .pointer {
                    color: #84c99e;
                }
            }
        }
        .status-campagne {
            &.preperation::before {
                background-color: #ef6e7e;
            }
            &.completed::before {
                background-color: #84c99e;
            }
            &.in-progress::before {
                background-color: #84c99e;
            }
        }
        
        table {
            border: none;
            table-layout: fixed;
            tbody tr:nth-child(2n+1), tbody tr:nth-child(2n+2) {
                background-color: transparent !important;
            }
            tr {                
                td {
                    background-color: transparent !important;
                    &::before, &::after {
                        display: none !important;
                    }
                }
            }
        }
    }
    .full-name {
        font-family: Lato-Heavy;
        font-size: 12px;
        margin-top: 13px;
    }
    .campagne {
        font-weight: 600;
    }
    .mt-3 {
        margin-top: 20px !important;
    }
    .status {
        font-weight: 700;
        font-size: 10px;
    }
    .code-total {
        font-size: 14px;
        font-family: Lato-Heavy;
    }
    .action {
        width: 40px !important;
        border-left: none;
        vertical-align: middle;
    }
    .status-campagne::before {
        vertical-align: 0;
    }
    .dropdown-dashboard-relative {
        padding: 20px 3px 20px 25px;
        background-color: #fff;
        position: relative;
        &::after {
            content: "";
            display: block;
            left: -1px;
            right: -54px;
            top: 0;
            background: url(/images/bg-top-table.png) top left repeat-x;
            height: 8px;
            position: absolute;
        }
        .item {
            border-bottom: 1px solid #e0e0e0;
            color: #5a5a5a;
            font-size: 12px;
            font-weight: 600;
            padding: 3px 8px 2px;
            .num {
                font-weight: 700;
                font-size: 14px;
            }
        }
        .dropdown-header {
            padding: 0;
            display: flex;
            justify-content: space-between;
            padding: 3px 8px 2px;
            font-family: Lato-Heavy;
            font-size: 12px;
            .num {
                font-size: 14px;
            }
        }
        .pointer {
            padding-top: 10px;
        }
    }
}
.pointer {
    cursor: pointer;
}
.progress-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .progress {
        width: calc(100% - 55px) !important;
        padding-right: 12px;
    }
    .txt {
        width: 55px;
        text-align: center !important;
        font-family: Lato-Heavy;
    }
}
.block-view {
    position: relative;
    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #e2e2e3;
    }
    // border-bottom: 1px solid #e2e2e3;
}

.filters-timesheet {
    .filter-date-control {
        .day-control {
            width: 100%;
            text-align: center;
            background-color: #eee;
            &.active {
                background-color: #fc6a28;
                color: #fff;
            }
        }
    }
}

.filters-block {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
    padding: 0 20px;

    .custom-input-date {
        font-family: Lato-Heavy, sans-serif;
        font-size: 1rem;
        color: #5a5a5a;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;

        &:hover {
            color: #fc6a28;
        }
    }

    .filter-block-date {
        float: left;
        .filter-date {
            width: 120px;
        }
        .col {
            padding-left: 5px;
            padding-right: 5px;

            &.pl-0 {
                padding-left: 5px!important;
            }

            // .react-datepicker__close-icon {
            //     right: 15px;
            // }
        }
        label.icon {
            display: none;
        }
        .filter-date {
            background: url(/images/icons/Icons-calendar.svg) 94% center no-repeat #f2f2f2;
            background-size: 12px 12px;
        }
    }

    .filter-block-date-control {
        float: right;
        min-width: 160px;
        .filter-date-control {
            position: relative;
            top: -3px;
            .col-md-6 {
                &:last-child {
                    padding-right: 20px;
                }
            }
            .day-control {
                width: 100%;
                text-align: center;
                background-color: transparent;
                text-transform: uppercase;
                color: #959595;
                padding: .375rem 0;
                font-size: 11px;
                &.active {
                    color: #000;
                }
            }
        }
    }
    
    .sort-by {
        width: 115px;
        float: left;
        display: block;
        border: none;
        padding: 0;

        .label-filter {
            color: #5a5a5a;
            font-size: 10px;
            font-weight: 600;
            margin-right: 12px;
            margin-top: 5px;
            float: left;
        }
    
        .showing-record {
            margin-right: 10px;
            float: left;
    
            .form-control {
                height: 25px;
                border-radius: 3px;
                background-color: #f2f2f2;
                font-size: 10px;
                font-weight: 400;
                padding: 0 8px 2px;
                color: #5a5a5a;
                max-width: 200px;
            }
        }
    }

    .filter-assignation {
        .col-md-4 {
            padding-left: 5px;
            padding-right: 5px;

            &:first-child {
                padding-left: 15px;
            }
        }
    }
    
}

.date-label {
    .filter-calendar {
        height: 25px;
        width: 420px;
        margin: auto;
        align-items: center;

        label {
            display: none;
        }
    }

    h2.date-select-str {
        font-size: 1rem;
        font-family: Lato-Heavy, sans-serif;
        text-transform: uppercase;
        color: #5a5a5a;
        margin: 0;
        font-weight: 500;
        line-height: 1.7;
        text-align: center;
    }

    .date-arrow {
        padding-left: 0;
        padding-right: 0;

        .date-arrow-previous, .date-arrow-next {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding-bottom: 0;
            padding-top: 5px;
            cursor: pointer;
            
            span {
                vertical-align: middle;
                font-size: 1.5em;
            }

            &:hover {
                svg {
                    color:#fc6a28;
                }
            }

            &.disabled {
                cursor: none;
                pointer-events: none;
                svg {
                    color:#ddd;
                }
            }
        }

    }
}

.modal-title-assignment {
    max-width: 100%!important;
}

.modal-new-assign .modal-body{
    padding: 30px 45px!important;

    .custom-loading {
        inset: 5px 0px 1px!important;
    }
}

.filter-assignation {
    .form-filter {
        position: relative;

        &:after {
            height: 12px;
            content: "";
            background-repeat: no-repeat;
            background-position: center;
            transition: transform 0.3s;
            transform: translate(0, -50%) rotate(-90deg);
            background-image: url(/images/icons/arrow-drop-down-icon.svg);
            background-color: #f2f2f2;
            position: absolute;
            top: 50%;
            right: 3px;
            width: 20px;
            transform: translate(0, -50%) rotate(0);
        }

        select {          
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        .form-control {
            border-color: #f2f2f2;
            background-color: #f2f2f2;
            height: 25px;
            border-radius: 3px;
            font-weight: 400;
            font-size: 10px;
            color: #5a5a5a;
            padding: 2px 8px;
        }
    }
    
}

.header-sendmail-button {
    position: absolute;
    right: 15px;
    top: -40px;
    .btn-sendmail {
        background-color: transparent;
        // color: rgb(130, 130, 130);
        svg {
            width: 18px;
            height: auto;
            filter: invert(53%) sepia(10%) saturate(4%) hue-rotate(21deg) brightness(94%) contrast(93%);
        }
    }
}

.react-tags-wrapper {
    .tag-item {
        color: #000;
        font-size: 12px;
        display: inline-block;
        padding: 3px 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        border: none;
        border-radius: 3px;
        background-color: #f1f1f1;
        .ReactTags__remove {
            color: #000;
            margin-left: 5px;
            cursor: pointer;
            border: none;
            background-color: #c4c4c4;
        }
    }
    .ReactTags__tagInput {
        margin-top: 10px;
    }
}

.pd-t-0 {
    padding-top: 0;
}

.pd-0 {
    padding: 0;
}

.listview-assignation {
    overflow: auto;
    max-height: calc(100vh - 290px); //750px;
    border-bottom: 1px solid #e5e5e5;
    z-index: 10;

    .dropright {
        .dropdown-toggle {
            &:after {
                border-top: 0.45em solid transparent;
                border-bottom: 0.45em solid transparent;
                border-left: 0.45em solid;
            }
        }
    }

    .custom-loading {
        background-color: #eaeaec;
    }

    table {
        border-bottom: none;
        width: 100%;
        z-index: 1;
        border-collapse: collapse;

        thead {
            position: relative;
            border-top: 1px solid #e5e5e5;
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;

            th {
                position: sticky;
                top: 0;
                z-index: 100;
                border: 1px solid #e5e5e5;
                background-color: #fff;
                color: #5a5a5a;

                &.height-fixed-50 {
                    height: 50px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    border-top: 1px solid #e5e5e5;
                    display: block;
                    top: -1px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-bottom: 1px solid #e5e5e5;
                    display: block;
                    top: 1px;
                }

                &.th-checkbox {
                    padding-right: 15px;

                    .checkbox-all-header {
                        z-index: 10;
                    }
                }

                &.action-head {
                    width: 100px;
                }

                &.th-checkbox {
                    border-right: 1px solid transparent;
                }

                &:first-child {
                    border-left: 1px solid transparent !important;
                }

                &:last-child {
                    border-right: 1px solid transparent !important;
                }
            }

            tr {
                &:nth-child(1) {
                    th {
                        position: sticky;
                        top: 0px;
                        z-index: 100;
                        border-left: 1px solid #e5e5e5;
                        padding-right: 0;
                    }
                }

                &:nth-child(2) {
                    th {
                        position: sticky;
                        top: 31px;
                        z-index: 100;
                    }
                }
            }
        }

        tbody {
            tr {
                &:first-child {
                    position: relative;

                    &:after {
                        content: "";
                        display: block;
                        left: -1px;
                        right: 0;
                        top: 0;
                        background: url(/images/bg-top-table.png) top left repeat-x;
                        height: 8px;
                        position: absolute;
                    }
                }

                &:nth-child(odd) {
                    background-color: #f9f9fb !important;
                    td {
                        &:last-child {
                            border-right: 1px solid #f9f9fb;
                        }
                    }
                }

                &:nth-child(even) {
                    background-color: #fff !important;

                    td {
                        &:first-child {
                            padding-right: 15px;
                            background-color: #f0f0f0;
                            border-right: 1px solid #f0f0f0;
                            border-left: 1px solid #f0f0f0;
                        }

                        &:nth-child(2) {
                            background-color: #f0f0f0;
                        }

                        &:last-child {
                            border-right: 1px solid #fff;
                        }
                    }
                }

                td {
                    border: 1px solid;
                    border-color: #e5e5e5;
                    padding: 0.75rem 0;
                    border-bottom: 1px solid #ccc;

                    &:first-child {
                        padding-right: 15px;
                        background-color: #eaeaec;
                        border-right: 1px solid #eaeaec;
                        border-left: 1px solid #eaeaec;
                    }

                    &:nth-child(2) {
                        background-color: #eaeaec;
                        border-left: 1px solid #eaeaec;
                    }

                    &.no-break-line {
                        padding: 0;

                        p {
                            white-space: nowrap;
                            padding: .3rem .75rem;
                            border-bottom: 1px solid #f1f1f1;
                            border-right: 1px solid #f1f1f1;
                            margin-bottom: 0;
                            height: 30px;

                            &:last-child {
                                border-color: transparent;
                                border-right: 1px solid #f1f1f1;
                            }

                            &.border-left {
                                border-left: 1px solid #f1f1f1 !important;
                            }
                        }

                        .row-item {
                            display: flex;
                            align-items: center;
                            border-bottom: 1px solid #f1f1f1;
                            border-left: 1px solid #f1f1f1;

                            p {
                                white-space: nowrap;
                                padding: 0 .75rem;
                                border: none;
                                height: auto;
                            }

                            &:last-child {
                                border-color: transparent;
                                border-left: 1px solid #f1f1f1;
                            }
                        }
                    }

                    &.loading-listview-timesheet {
                        position: relative;
                        height: calc(100vh - 303px);
                        border: none;
                    }

                    &.no-border {
                        border: none;
                        border-color: transparent;
                    }

                    &.text-center {
                        text-align: center;
                    }

                    p.line-inside {
                        margin-bottom: 0;
                        border-bottom: 1px solid #e5e5e5;
                        padding: .25rem .75rem;
                        height: 27px;
                        white-space: nowrap;
                        color: #5a5a5a;

                        &:last-child {
                            border: none;
                        }
                    }

                    &.td-checkbox {
                        padding-left: 15px;
                        padding-right: 15px;
                    }

                    .full-name {
                        font-weight: bold;
                        color: #5a5a5a;
                    }

                    .dropright {
                        button {
                            color: #5a5a5a;
                            &:after {
                                color: #5a5a5a;
                            }
                        }
                    }
                }
                &:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }

        }

        tfoot {
            &.footer-total {
                position: relative;

                tr {
                    position: sticky;
                    bottom: 0px;
                    z-index: 100;
                    background-color: #f9f9fb;
                    height: 30px;

                    td {
                        border-left: 1px solid #ddd;
                        background-color: #fff;
                        font-family: Lato-Heavy, sans-serif;
                        color: #fc6a28;

                        &.text-normal {
                            font-family: Lato, sans-serif;
                        }

                        &.pd-r-10 {
                            padding-right: 10px;
                        }

                        &:first-child {
                            border: none;
                            background-color: #eee;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            right: 0;
                            border-top: 1px solid #fc6a28;
                            display: block;
                            bottom: 0px;
                        }

                        &.action-footer {
                            button {
                                position: relative;
                                z-index: 200;
                                font-size: 12px;
                                top: 3px;

                                svg {
                                    width: 18px;
                                    height: 18px;
                                    top: -2px;
                                    left: 0;
                                }
                            }
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        left: -1px;
                        width: 100%;
                        height: 100%;
                        border-left: 1px solid #eee;
                        border-bottom: 1px solid #ddd;
                        display: block;
                        top: 1px;
                    }
                }
            }
        }
    }

    .toggle-list {
        max-height: 400px;
        overflow: auto;
    }
}