.ico-close-circle {
    background: url(/images/icons/Icons-close-c8c8c8.svg) no-repeat;
    display: inline-block;
    width: 13px;
    height: 13px;
    background-size: 100% 100%;
    vertical-align: -3px;
    margin-left: 2px;
}
.btn-clear {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}
.list-table-status {
    table {
        border-bottom-color: #f2f2f2;
        th, td {
            &.text-center:first-child {
                padding-right: 15px;
            }
        }
        tbody tr {
            &:hover {
                td {
                    &:first-child {
                        &::before {
                            display: none;
                        }
                    }
                    &.td-border-right {
                        &::before {
                            content: "";
                            display: none;
                            width: 3px;
                            height: 100%;
                            left: 0;
                            top: 0;
                            background: #f27442;
                            background: linear-gradient(0deg, #f27442 0%, #f9ae72 100%);
                            position: absolute;
                        }
                    }
                }
                td.td-rowspan:first-child {            
                    &::before {
                        display: none;
                    }
                }
            }            
            td {
                &.td-bg-opacity + .td-bg-opacity {
                    border-left: none;
                }
                &.td-rowspan:first-child {
                    border-left: 1px solid #fff;
                }
                &.td-border-right {
                    border-right: 1px solid #fff;
                }
            }
            &:nth-child(2) {
                td {
                    position: relative;
                    &::after {
                        content: "";
                        display: block;
                        left: -1px;
                        right: 0;
                        top: 0;
                        background: url(/images/bg-top-table.png) top left repeat-x;
                        height: 8px;
                        position: absolute;
                    }
                }
            }
            &:not(:first-child) {
                td:not(:first-child) {
                    border-bottom: 1px solid #f2f2f2;
                }
                td:nth-child(2) {
                    border-bottom: none;
                }
            }
            &.tr-rowspan {
                border-bottom: 3px solid #dbdbdc;
                td {
                    border-top: 1px solid #e5e5e5;
                }
            }
            &.tr-dark {
                background-color: #f9f9fb !important;
                .td-bg-opacity {
                    background-color: #eaeaec;
                }
            }
            &.tr-lighter {
                background-color: #fff !important;
                .td-bg-opacity {
                    background-color: #f0f0f0;
                }
            }
        }
        .td-min-w {
            min-width: 112px;
        }
        .td-60 {
            width: 60px;
            min-width: 60px;
        }
        .td-55 {
            width: 55px;
            min-width: 55px;
        }
        input {
            min-height: 24px;
            border: none;
            border-radius: 3px;
        }
        .td-heavy {
            input {
                font-family: 'Lato-Heavy', sans-serif;
            }
        }
    }
    .react-datepicker__close-icon::after {
        background-color: #c0c0c0;
        width: 13px;
        height: 13px;
        color: #eaeaec;
        display: block;
        padding: 0;
        text-align: center;
        line-height: 13px;
        font-size: 10px;
        font-weight: 700;
    }
}
.w-115 {
    width: 115px;
}
.add-row-block {
    &.is-border-bottom {
        border-bottom: 1px solid #f2f2f2;
        .btn-add-user {
            margin-bottom: 5px;
        }
    }
}