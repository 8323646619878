.wh-50 {
    width: 50%;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    a {
        color: #768192;
        &.active {
            color: #FF6F1B!important;
        }
    }
}

.upload-file-transfer {
    position: relative;
    overflow: hidden;
    border-top: 1px solid #c6c6c5;
    border-bottom: 1px solid #c6c6c5;
    color: #898989;
    line-height: 1.2;
    padding: 14px 0 14px 50px;
    margin-bottom: 16px;
    span {
        position: absolute;
        top: 8px;
        left: 5px;
        width: 40px;
        height: 40px;
        display: block;
        background: url(/images/plus-circle-solid.png) no-repeat;
        background-size: 45px 45px;
        background-position: center;
    }
    input {
        cursor: pointer;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    strong {
        display: block;
        font-weight: 900;
    }
}

.card-action-form {
    border: none;
    .action-form-box {
        padding: 3.6rem 6.5rem;
        h3.title-inside {
            font-weight: 600;
            color: #000;
        }
        .send-step-2 {
            min-height: 350px;
            animation: fadeInBack 1s;
            -webkit-animation: fadeInBack 1s;
            -moz-animation: fadeInBack 1s;
            -o-animation: fadeInBack 1s;
            -ms-animation: fadeInBack 1s;
            .icon {
                text-align: center;
                margin-top: 100px;
                img {
                    width: 100px;
                    margin-bottom: 10px;
                }
            }
            .title-transfer {
                text-align: center;
                margin-bottom: 0;
                color: #000;
                margin-bottom: 10px;
            }
            .message-wait {
                text-align: center;
                line-height: 1.2;
            }
            .progress-bar-custom {
                margin-bottom: 2rem;
                padding: 0 30px;
                .progress-sm {
                    height: 4px;
                }
            }
            .text-center {
                button {
                    border-radius: 2rem;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
        .receiver-tab {
            .desc-tab {
                position: relative;
                overflow: hidden;
                border-top: 1px solid #c6c6c5;
                border-bottom: 1px solid #c6c6c5;
                color: #898989;
                line-height: 1.2;
                padding: 14px 0;
                margin-bottom: 16px;
                span {
                    position: absolute;
                    top: 11px;
                    left: 5px;
                    width: 33px;
                    height: 33px;
                    display: block;
                    background: url(/images/plus-circle.png) no-repeat;
                }
                input {
                    cursor: pointer;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                strong {
                    display: block;
                    font-weight: 900;
                }
            }
        }
        .nav-tabs {
            border-bottom: none;
            border: none;
            position: relative;
            &::before {
                content: "";
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 50%;
                background-color: #d7d7d7;
                border-radius: 10px 10px 0 0;
                position: absolute;
            }
            .nav-item {
                .nav-link {
                    background-color: #d7d7d7;
                    color: #fff;
                    border: none;
                    position: relative;
                    &.active {
                        background-color: #fff;
                        color: #768192!important;
                    }
                }
                &:first-child {
                    a {
                        border-radius: 10px 0 10px 0;
                        &.active {
                            border-radius: 10px 10px 0 0;
                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: -1;
                                background-color: #d7d7d7;
                                border-radius: 10px 0 0 0;
                            }
                        }
                    }
                }
                &:last-child {
                    a {
                        &.nav-link {
                            border-radius: 0 10px 0 10px;
                        }
                        &.active {
                            border-radius: 10px 10px 0 0;
                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: -1;
                                background-color: #d7d7d7;
                                border-radius: 0 10px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.files-in-tranfer {
    h4 {
        font-family: Lato-Heavy, sans-serif;
        color: #252525;
        font-size: 18px;
        margin: 0 0 14px;
        text-transform: uppercase;
    }
    .card-body {
        padding: 0;
        .nav-tabs {
            border: none;
            position: relative;
            margin-bottom: 20px;
            &::before {
                content: "";
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 50%;
                background-color: #d7d7d7;
                border-radius: 10px 10px 0 0;
                position: absolute;
            }
            .nav-item {
                margin-bottom: -1px;
                .nav-link {
                    text-transform: uppercase;
                    font-size: 12px;
                    border: none;
                    background-color: #d7d7d7;
                    color: #fff;
                    padding: .6rem 1.2rem;
                    position: relative;
                    &.active {
                        background-color: #fff;
                        color: #768192 !important;
                    }
                }
                &:first-child {
                    a {
                        border-radius: 10px 0 10px 0;
                        &.active {
                            border-radius: 10px 10px 0 0;
                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: -1;
                                background-color: #d7d7d7;
                                border-radius: 10px 0 0 0;
                            }
                        }
                    }
                }
                &:last-child {
                    a {
                        &.nav-link {
                            border-radius: 0 10px 0 10px;
                        }
                        &.active {
                            border-radius: 10px 10px 0 0;
                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: -1;
                                background-color: #d7d7d7;
                                border-radius: 0 10px 0 0;
                            }
                        }
                    }
                }
            }
        }
        .table-striped {
            thead {
                th {
                    color: #768192;
                }
            }
            tbody {
                tr:nth-of-type(odd) {
                    background-color: #f9f9fb;
                }
                tr {
                    td {
                        &.name-of-file {
                            color: #ff823e;
                            font-weight: 600;
                        }
                        &.address-url-of-file {
                            a {
                                color: #a5a5a5;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
    .search-user {
        min-width: 230px;
        .form-control {
            height: 27px;
            font-size: 0.76563rem;
            padding: 0 8px;
            border-radius: 14px;
            padding-left: 14px;
            padding-right: 14px;
            background-color: #f0f2f2;
            border-color: #f0f2f2;
        }
    }
}

.ico-informations-cccccc {
    display: inline-block;
    background: url(/images/icons/Icons-Informations-cccccc.svg);
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    &:hover {
        background: url(/images/icons/Icons-Informations-cccccc-o.svg);
    }
}