// If you want to override variables do it here
@import "variables";
// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";
@import "mixins";
// If you want to add something do it here
@import "pagination";
@import "custom";
@import "auth";
@import "sidebar";
@import "print";
@import "logs";
@import "support";
@import "sidebar";
@import "timesheet";
@import "myhub";
@import "agent";
@import "templates";
@import "rentabilite-des-agents";
@import "client_hub";
@import "status-of-list";
@import "transfer";

