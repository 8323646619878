.rentabilite-des-agents {
    .filters-user .form-control {
        font-size: 10px;
    }
    .listview-timesheet table thead {
        position: sticky;
        top: 0;
        z-index: 10;
        tr {
            border-bottom: 1px solid #DDD;
            &.total th {
                white-space: nowrap
            }
        }
        th {
            max-width: 100px;
            text-align: center;
            border-right: 1px solid #DDD;
        }
    }
}