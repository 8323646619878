.c-sidebar {
    background: $clr-bg-sidebar;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    .sidebar-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 11px 13px 4px;
    }

    .c-sidebar-brand {
        background: none;
        flex: 0 0 95px;
        width: 95px;

        img {
            max-width: 95px;
        }
    }

    .c-sidebar-footer {
        background: $clr-bg-sidebar;
        color: #828282;
        font-size: 9px;
        span {
            font-weight: 500;
        }
        p {
            // color: #6d6d6d;
            margin-bottom: 0;
        }
    }

    .c-sidebar-minimizer {
        background-color: $clr-bg-sidebar;
    }

    .c-header-toggler {
        width: 15px;
        height: 12px;
        position: relative;
        min-width: unset;

        &::before,
        &::after,
        .c-header-toggler-icon {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            background-color: #5e5e5e;
            height: 2px;
            transition: all ease 0.5s;
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }

        .c-header-toggler-icon {
            top: 5px;
        }

        &:hover {

            &::before,
            &::after,
            .c-header-toggler-icon {
                background-color: #fff;
            }
        }
    }

    .sidebar-toggle {
        position: absolute;
        right: -25px;
        top: 10px;
        z-index: 10;
        background: url(/images/right-arrow.png) no-repeat center center;
        width: 15px;
        height: 20px;
        border: none;
        transition: all ease 0.3s;
        opacity: 1;
        visibility: visible;

        &::before,
        &::after,
        span {
            display: none;
        }
    }

    &.c-sidebar-show {
        .sidebar-toggle {
            opacity: 0;
            visibility: hidden;
        }
    }
    .c-sidebar-nav-link, .c-sidebar-nav-dropdown-toggle {
        padding-top: 9px;
        padding-bottom: 8px;
    }
    .c-sidebar-nav-dropdown {
        z-index: 0;

        &.c-show>.c-sidebar-nav-dropdown-toggle {
            &::before {
                transform: translate(0,-50%)  rotate(0);
            }
        }

        .c-sidebar-nav-dropdown-toggle {
            font-size: 12px;
            font-weight: bold;
            color: #828282;
            position: relative;            
            padding: 9px .3rem 8px 23px;
            &:hover {
                background: rgba(255, 255, 255, 0.05);
            }

            svg {
                display: none;
            }

            &::before {
                display: block;
                flex: 0 1 20px;
                height: 12px;
                content: "";
                background-repeat: no-repeat;
                background-position: center;
                transition: transform 0.3s;
                transform: translate(0,-50%) rotate(-90deg);
                background-image: url(/images/icons/arrow-drop-down-icon.svg);
                position: absolute;
                top: 50%;
                left: 3px;
                width: 20px;
            }

            &::after {
                display: none;
            }

        }

        // CHILD 1
        .c-sidebar-nav-dropdown-items {

            .c-sidebar-nav-item {
                position: relative;
                
                .c-sidebar-nav-link {
                    padding-left: 35px;
                    margin-left: 15px;
                    margin-right: 15px;
                    // padding-left: 50px;
                    color: #fff;
                    font-size: 14px;
                    &::before {
                        content: "";
                        display: block;
                        left: 18px;
                        top: 0;
                        width: 26px;
                        height: 100%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        position: absolute;
                        background-size: 17px 17px;
                    }

                    &:hover {
                        background: rgba(255, 255, 255, 0.05);
                        border-radius: 3px;
                    }

                    svg {
                        display: none;
                    }
                }

                &.link-dashboard {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-NewDash.svg);
                        }
                    }
                }
                
                &.link-informations {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Informations.svg);
                        }
                    }
                }

                &.link-transfer-download {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-transfert-download.svg);
                        }
                    }
                }

                &.link-transfer-upload {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-transfert-upload.svg);
                        }
                    }
                }

                &.link-status-list {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Segment-List-Stat.svg);
                        }
                    }
                }

                &.link-agents {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-agents.svg);
                        }
                    }
                }

                &.link-donors {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Donators.svg);
                        }
                    }
                }

                &.link-export {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-ImportExport.svg);
                        }
                    }
                }

                &.link-results {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Results.svg);
                        }
                    }
                }

                &.link-rentability {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Rentability.svg);
                        }
                    }
                }

                &.link-campagne {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Campaign.svg);
                        }
                    }
                }

                &.link-templates {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Templates.svg);
                        }
                    }
                }

                &.link-users {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Users.svg);
                        }
                    }
                }

                &.link-clients {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Clients.svg);
                        }
                    }
                }

                &.link-privileges {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Privileges.svg);
                        }
                    }
                }

                &.link-billing {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Billing.svg);
                        }
                    }
                }

                &.link-logs {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Logs.svg);
                        }
                    }
                }

                &.link-timesheet {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-timesheet.svg);
                        }
                    }
                }

                &.link-agent-time {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Agent-time-management.svg);
                        }
                    }
                }

                &.link-notifications {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-communication.svg);
                        }
                    }
                }

                &.link-support {
                    .c-sidebar-nav-link {
                        &::before {
                            background-image: url(/images/icons/Icons-Settings.svg);
                        }
                    }
                }
            }

            // CHILD 2
            .c-sidebar-nav-dropdown {
                .c-sidebar-nav-link {
                    margin-left: 0;
                    margin-right: 0;
                }
                &.c-show>.c-sidebar-nav-dropdown-toggle {
                    &::before {
                        transform: rotate(-90deg);
                    }
                    &::after {
                        transform: translate(0, -50%) rotate(0);
                        left: 3px;
                    }
                }

                .c-sidebar-nav-dropdown-toggle {
                    padding-left: 50px;
                    font-size: 14px;
                    color: #fff;
                    font-weight: normal;
                    svg {
                        display: none;
                    }

                    &::before {
                        content: "";
                        display: block;
                        left: 18px;
                        top: 0;
                        width: 26px;
                        height: 100%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        position: absolute;
                        background-size: 17px 17px;
                        background-image: url(/images/icons/Icons-Analytic.svg);
                        transform: inherit;
                    }

                    &::after {
                        display: block;
                        flex: 0 1 20px;
                        height: 12px;
                        content: "";
                        background-repeat: no-repeat;
                        background-position: center;
                        transition: transform 0.3s;
                        transform: translate(0, -50%) rotate(-90deg);
                        background-image: url(/images/icons/arrow-drop-down-icon.svg);
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 20px;
                    }
                }

                &.link-transfer {
                    .c-sidebar-nav-dropdown-toggle {
                        &::before {
                            background-image: url(/images/icons/Icons-Transfert.svg);
                            width: 15px;
                            height: 16px;
                            left: 23px;
                            top: 11px;
                            background-size: 100% 100%;
                        }
                    }
                }

                .c-sidebar-nav-dropdown-items {
                    padding-left: 40px;
                    .c-sidebar-nav-item {
                        position: relative;

                        .c-sidebar-nav-link {
                            padding-left: 35px;
                            color: #828282;
                            &::before {
                                left: 4px;
                            }
                            &:hover {
                                // background: rgba(255, 255, 255, 0.05);
                                background: none;
                                color: #cecece;
                            }
                            &.c-active {
                                background: rgba(255, 255, 255, 0.05);
                                color: #cecece;
                                border-radius: 3px;
                            }
                        }

                        &.link-dashboard {
                            .c-sidebar-nav-link {
                                &::before {
                                    background-image: url(/images/icons/Icons-NewDash.svg);
                                }
                            }
                        }

                        &.link-statistics {
                            .c-sidebar-nav-link {
                                &::before {
                                    background-image: url(/images/icons/Icons-Stats.svg);
                                }
                            }
                        }

                        // &.c-active {
                        //     .c-sidebar-nav-link {
                        //         background: rgba(255, 255, 255, 0.05);
                        //         color: #fff;
                        //         border-radius: 3px;
                        //     }
                        // }
                    }
                }

                &.btn-archive {
                    .c-sidebar-nav-dropdown-toggle {
                        padding-left: 41px;
                        font-size: 12px;
                        color: #828282;
                        font-weight: 300;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        text-decoration: underline;

                        svg {
                            display: none;
                        }
    
                        &::before {
                            content: none;
                        }
    
                        &::after {
                            display: none;
                        }
                    }

                    .c-sidebar-nav-dropdown-items {
                        padding-left: 25px;
                    }
                }
            }
        }

        &.c-show {
            background: $clr-bg-sidebar;

            .c-sidebar-nav-dropdown-toggle {
                color: #828282
            }

        }
    }

    .border-between {
        border: 0;
        border-top: 1px solid #5e5e5e;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .nav-clients {
        .box-campagne-active {
            padding: 0 11px;
            display: flex;
            align-items: flex-start;
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            width: 100%;
            text-align: left;
            cursor: pointer;

            &.user-login {
                align-items: center;
                border-top: 1px solid #5e5e5e;
                padding-top: 10px;
                &::after {
                    top: 0;
                }
            }

            .c-avatar {
                width: 29px;
                height: 29px;
                border-radius: 50%;
                background-color: #fff;
                overflow: hidden;
                min-width: 29px;

                img {
                    width: 29px;
                    height: 29px;
                    border-radius: 50%;
                }
            }

            .c-avatar-text {
                width: calc(100% - 29px);
                padding-left: 10px;
                white-space: break-spaces;

                p {
                    margin-bottom: 5px;
                    line-height: 1.2;

                    &.campange-active-name {
                        font-family: Lato, sans-serif;
                        font-size: 10px;
                        color: #AA522B
                    }

                    &.all-campagne-active {
                        font-family: Lato, sans-serif;
                        font-size: 14px;
                        line-height: 2;
                    }
                }
            }

            &::after {
                display: inline-block;
                vertical-align: 0.255em;
                content: "";
                // border-top: 0.3em solid;
                // border-right: 0.3em solid transparent;
                // border-bottom: 0;
                // border-left: 0.3em solid transparent;
                color: #858585;
                transition: all ease 0.3s;
                position: relative;
                top: 10px;
                right: -5px;
                transform: rotate(-90deg);
                background-image: url(/images/icons/arrow-drop-down-icon.svg);
                background-repeat: no-repeat;
                width: 20px;
                height: 12px;
                background-position: center center;
            }

            &.show-navs {
                &::after {
                    transform: rotate(0deg);
                }
            }
        }

        .box-nav-list {
            // transition: all ease-in-out .3s;
            position: relative;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-in-out;
            .box-nav-wrap {
                padding-left: 15px;
            }
            .nav-list-scroll {
                overflow-x: hidden;
                overflow-y: auto;
                &::-webkit-scrollbar-track {
                    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    border-radius: 10px;
                    background-color: transparent;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: transparent;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                    background-color: #aaa;
                }
            }
            .nav-list-limited {
                transition: all 0.3s ease-in-out;
                // max-height: 180px;
                
                .c-sidebar-nav {
                    > li:nth-of-type(n+6) {
                        max-height: 0;
                        overflow: hidden;
                    }
                }
            }
            .nav-list-unlimited {
                max-height: 300px !important;
                .c-sidebar-nav {
                    // max-height: 300px !important;
                    li:nth-of-type(n+6) {
                        max-height: 1500px;
                    }
                }
            }
            .c-sidebar-nav {
                // transition: all 0.3s ease-in-out;
                // max-height: 180px;
                li {
                    transition: all 0.3s ease-in-out;
                }
            }
            .ps__rail-y {
                z-index: 1;
            }

            .c-sidebar-nav-link {
                cursor: pointer;
                color: #858585;
                padding: 0.5rem 1rem;
                font-size: 14px;
                margin: 0 !important;
                &:hover {
                    background: rgba(255, 255, 255, 0.05);
                }

                &.no-parent {
                    padding-left: 40px;
                }

                &.c-archive {
                    padding-left: 100px!important;
                    &::before {
                        background-image: url(/images/icons/Icons-Archives.svg);
                        left: 75px;
                        top: -2px;
                    }
                }
            }

            &.show-navs {
                // height: 300px; 
                max-height: 1500px;
            }

            .c-sidebar-nav-dropdown-toggle {
                padding: 6px 18px 6px 29px;
                border-radius: 3px;
                font-size: 14px;
                color: #fff;
                font-weight: 400;
            }

            .c-sidebar-nav-dropdown-items {
                .c-sidebar-nav-link {
                    padding-left: 41px;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    color: #828282;
                    font-size: 12px;
                    font-weight: 300;
                    background-color: transparent;
                    &:hover {
                        background: none;
                        color: #cecece;
                    }
                }
            }
        }

        .box-nav-user-list {
            transition: all ease-in-out .3s;
            position: relative;
            height: 0;
            overflow: hidden;

            .ps__rail-y {
                z-index: 1;
            }

            .c-sidebar-nav-link {
                cursor: pointer;

                &:hover {
                    background: rgba(255, 255, 255, 0.05);
                }
            }

            &.show-navs {
                height: 60px;
            }

            .nav-user-list {
                list-style: none;

                li {
                    span {
                        display: flex;
                        flex: 1 1;
                        color: #828282;
                        background: transparent;
                        text-align: center;
                        padding: .3rem .6rem;
                        text-decoration: none;
                        white-space: nowrap;
                        transition: background 0.3s, color 0.3s;
                        cursor: pointer;

                        &:hover {
                            color: #fff;
                            // background: rgba(255, 255, 255, 0.05);
                        }
                        &:focus {
                            color: #fff;
                            background: rgba(255, 255, 255, 0.05);
                        }
                    }
                }
            }
        }

    } 
    .c-sidebar-nav-link, .c-sidebar-nav-dropdown-toggle {
        white-space: normal;
    }
}

.user-dropdown {
    padding: 0 6px 0 7px;

    .c-avatar {
        width: 29px;
        height: 29px;
        border-radius: 50%;
        background-color: $clr-white;
        overflow: hidden;
        min-width: 29px;

        img {
            width: 29px;
            height: 29px;
            border-radius: 50%;
        }
    }

    .c-avatar-text {
        width: calc(100% - 29px);
        padding-left: 10px;
        white-space: break-spaces;
    }

    .dropdown-toggle {
        padding: 0 6px 0 5px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: $clr-white;
        width: 100%;
        text-align: left;

        &::after {
            color: #858585;
            transform: rotate(180deg);
            transition: all ease 0.3s;
        }
    }

    &.show {
        .dropdown-toggle {
            &::after {
                transform: inherit;
            }
        }
    }

    .dropdown-menu-dark {
        .dropdown-item {
            &.clickable-hide {
                cursor: auto;
            }
        }
    }
}

.dropdown-menu-dark {
    min-width: 243px;
    background-color: #464646;
    border: none;
    padding: 16px 8px 21px;
    border-radius: 3px;
    width: 243px;

    .dropdown-item {
        color: #828282;
        border-radius: 3px;
        padding: 5px 11px;
        display: block;
        white-space: break-spaces;

        &:not(:last-child) {
            margin-bottom: 5px;
        }

        &:hover,
        &:focus,
        &.active {
            background-color: #555555;
            color: $clr-white;
        }
    }

    .dropdown-menu-dark {
        position: absolute;
        top: 20px;
        left: 100%;
        z-index: 9;
        margin-left: 9px;
        opacity: 0;
        visibility: hidden;
        display: block;
        transition: all ease 0.3s;
        padding-top: 13px;
        padding-bottom: 18px;
    }

    .nav-item {
        position: relative;

        // &>a {
        //     display: block;
        //     color: inherit;
        //     text-decoration: none;
        //     &:hover,&:focus {
        //         color: inherit;
        //         text-decoration: none;
        //     }
        // }
        &:hover {
            .dropdown-menu-dark {
                opacity: 1;
                visibility: visible;
                top: -10px;
            }
        }
    }
}

.userAgent {
    .c-sidebar-nav-title-admin {
        display: none;
    }
}

.c-header-with-subheader {
    .c-header-toggler.d-md-down-none {
        opacity: 0;
        visibility: hidden;
        width: 0;
        transition: opacity ease 0.3s;
        padding: 0;
        min-width: 0;
    }
}
// toggle .c-sidebar-nav-dropdown-items
// .c-sidebar-nav-dropdown-items {
//     max-height: unset;
//     display: none;
// }
// .c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-items {
//     display: block;
// }
.sidebar-search {
    border-bottom: 1px solid #555555;
    padding: 20px 25px 16px;
    .wrap {
        position: relative;
    }
    input {
        background-color: #f2f2f2;
        height: 25px;
        border-radius: 3px;
        font-size: 10px;
        font-weight: 600;
        padding: 2px 30px 2px 10px;
        color: #5a5a5a;
        @include placeholder(#5a5a5a);
        border-color: #f2f2f2;
        &:focus {
            outline: none;
            box-shadow: none;
            border-color: #f2f2f2;
        }
    }
}
.btn-clear-input {
    padding: 4px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    text-align: center;
    display: none;
    &.active {
        display: block;
    }
}
.ico-clear-input {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: url(/images/icons/ico-clear-input.png) no-repeat;
    background-size: 100% 100%;
}
.sidebar-nav-toggle {
    button {
        color: #828282;
        font-size: 12px;
        &:hover, &:focus {
            color: #fff;
        }
    }
}
