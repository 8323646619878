.pagination {
    .page-item {
        &:not(:last-child) {
            margin-right: 4px;
        }
        .page-link {
            padding: 0;
            min-width: 16px;
            height: 22px;
            border-radius: 3px !important;
            // background-color: #f0f2f4;
            background-color: transparent;
            color: #5a5a5a;
            text-align: center;
            line-height: 20px;
            border: 1px solid transparent;
            &:hover {
                background-color: transparent;
                color: #202b36;
                border-color: #ececec;
            }
            &:focus {
                outline: 0;
                box-shadow: none;
            }
            .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
                height: 12px;
                font-size: 12px;
            }
            svg {
                background-repeat: no-repeat;
                background-size: 100% 100%;
                color: transparent !important;
                path {
                    opacity: 0;
                }
            }
            &[aria-label="Go to previous page"] {
                svg {
                    background-image: url(/images/icons/cil-arrow-left-normal.svg);
                }
            }
            &[aria-label="Go to next page"] {
                svg {
                    background-image: url(/images/icons/cil-arrow-right-normal.svg);
                }
            }
        }
        &.disabled {
            .page-link {
                display: none;
                &[aria-label="Go to previous page"] {
                    svg {
                        background-image: url(/images/icons/cil-arrow-left-normal.svg);
                    }
                }
                &[aria-label="Go to next page"] {
                    svg {
                        background-image: url(/images/icons/cil-arrow-right-normal.svg);
                    }
                    &:hover {
                        svg {
                            background-image: url(/images/icons/cil-arrow-right.svg);
                        }
                    }
                }
            }
        }
        &.active {
            .page-link {
                background-color: transparent;
                color: #202b36;
                border-color: #ececec;
            }
        }
    }
}

.pagination-site {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 10px;
    .show-result {
        color: #5a5a5a;
        margin-right: 12px;
        position: relative;
        top: -2px;
    }
}